import React from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./pages/Router"
import { ChakraProvider } from "@chakra-ui/react"
import ReactGA from "react-ga4"

import "./App.scss"

export default function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALITICS_ID || "")
  return (
    <BrowserRouter>
      <div className="App">
        <ChakraProvider>
          <Router />
        </ChakraProvider>
      </div>
    </BrowserRouter>
  )
}
