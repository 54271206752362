import React from "react"
import { Flex } from "@chakra-ui/react"
import PaymentVerticalCard from "../../paymentCard/PaymentVerticalCard"
import { Vehicle } from "../../../entities"
import PaymentSelectedVerticalCard from "../../paymentCard/PaymentSelectedVerticalCard"

interface VehicleSliderCardProps {
  vehicle: Vehicle
  selected: boolean,
  onSelectedCard: (vehicle: Vehicle) => void;
}

export default function VehicleSliderCard({ vehicle, selected, onSelectedCard }: VehicleSliderCardProps) {
  return (
    <Flex onClick={() => onSelectedCard(vehicle)}>
      {selected && (
        <PaymentSelectedVerticalCard vehicle={vehicle} />
      )}
      {!selected && (
        <PaymentVerticalCard vehicle={vehicle} />
      )}
    </Flex>
  )
}
