import { User, SearchParams, SearchResult } from "../../entities"
import { UserRepository } from "../../repositories/user/UserRepository"
import OneSignalStorage from "../../util/oneSignalStorage"
import UserStorage from "../../util/userStorage"
import { AuthService } from "../auth"

export class UserService {
  public static async search(params: SearchParams): Promise<SearchResult<User>> {
    const token = UserStorage.getToken()
    const result = await UserRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<User> {
    const token = UserStorage.getToken()
    return await UserRepository.get(id, token)
  }

  public static async create(user: User): Promise<User> {
    const newUser = await UserRepository.create(user)

    const oneSignal = OneSignalStorage.get() || ''

    await AuthService.login(user.email, user.password, oneSignal)
    return newUser
  }

  public static async update(user: User): Promise<User> {
    const token = UserStorage.getToken()
    return await UserRepository.update(user, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await UserRepository.delete(id, token)
  }

  public static async changePassword(password: string, newPassword: string, newPasswordConfirm: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await UserRepository.changePassword(password, newPassword, newPasswordConfirm, token)
  }

  public static async isValidUser(): Promise<boolean> {
    if (UserStorage.hasToken() === false) return false

    const isExpired = UserStorage.isTokenExpired()
    if (isExpired) return false

    const user = UserStorage.get()
    if (user?.isActive === false) return false

    return true
  }

  public static async updateOneSignalToken(oneSignalToken: string): Promise<void> {
    const token = UserStorage.getToken()
    await UserRepository.updateOneSignalToken(oneSignalToken, token)
  }
}