import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import InputMask from "react-input-mask"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Image,
  Button,
  useToast,
} from "@chakra-ui/react"
import PaymentCard from "../../components/paymentCard/PaymentCard"
import Layout from "../../components/layout/Layout/Layout"
import CheckoutStorage from "../../util/checkoutStorage"
import { CheckoutInput } from "../../entities/csg/CheckoutInput"

export default function PaymentInfo() {
  const navigate = useNavigate()
  const toast = useToast()

  const checkout = CheckoutStorage.get() || new CheckoutInput()

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
  })

  const updateFormDataPayment = (field: string, value: string) => {
    setForm({ ...form, [field]: value })
  }

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)
  // const isPhoneValid = /^\(\d{2}\) \d{5}-\d{4}$/.test(form.phone)
  const isFormValid =
    isEmailValid && Object.values(form).every((value) => value.trim() !== "")

  const save = () => {
    if (form.name.length < 3) {
      toast({
        title: "Digite o nome completo.",
        status: "error",
        duration: 2000,
      })
      return false
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return false
    }

    // const isPhoneValid = /^\(\d{2}\) \d{5}-\d{4}$/.test(form.phone)
    // if (!isPhoneValid) {
    //   toast({
    //     title: "Digite um telefone válido.",
    //     status: "error",
    //     duration: 2000,
    //   })
    //   return false
    // }

    if (form.phone.length < 5) {
      toast({
        title: "Digite um telefone válido.",
        status: "error",
        duration: 2000,
      })
      return false
    }

    checkout.name = form.name
    checkout.phone = form.phone
    checkout.email = form.email

    CheckoutStorage.set(checkout)

    navigate("/checkout/pagamento")
  }

  return (
    <Layout
      internal={true}
      menuSelected={"vehicle"}
      header={{
        title: "Informações gerais",
        backTo: `/checkout?licensePlate=${checkout.licensePlates[0]}`,
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Text fontSize="1rem" fontWeight="700" color="#2D3748">
          Confirmação de pagamento
        </Text>
        <VStack gap="1rem">
          <FormControl isRequired>
            <FormLabel>Nome</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.name}
              onChange={(e) => updateFormDataPayment("name", e.target.value)}
              placeholder="Nome completo"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>E-mail</FormLabel>
            <Input
              size="lg"
              type="email"
              value={form.email}
              onChange={(e) => updateFormDataPayment("email", e.target.value)}
              placeholder="nome@provedor.com"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Telefone</FormLabel>
            <Input
              // as={InputMask}
              // mask="(99) 99999-9999"
              // maskChar={null}
              size="lg"
              type="text"
              value={form.phone}
              onChange={(e) => updateFormDataPayment("phone", e.target.value)}
              placeholder="(00) 00000-0000"
            />
          </FormControl>
          <HStack
            bg="#F7F8F9"
            p="0.625rem 2rem 0.625rem 0.75rem"
            borderRadius="0.5rem"
            display="flex"
            gap="0.625rem"
            alignItems="center"
          >
            <Image src="/images/card-icon/Info.svg" alt="Ícone de informação" />
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              Após pagamento você recebe acesso ao DFE (Documento Fiscal
              Equivalente) por e-mail - https://cis.csg.com.br/.
            </Text>
          </HStack>
        </VStack>
        <VStack w="100%">
          <Text
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Dados do veículo
          </Text>
          <VStack w="100%">
            {checkout.licensePlates.map((licensePlate) => (
              <PaymentCard
                id={0}
                licensePlate={licensePlate}
                manufacturer="Veículo"
                model=""
                status=""
                statusBold=""
              />
            ))}
          </VStack>
        </VStack>
        <Flex w="100%">
          <Button
            w="100%"
            size="lg"
            fontSize="1rem"
            variant="solid"
            bg={!isFormValid ? "#E2E8F0" : "#00335B"}
            color={!isFormValid ? "#00335B" : "#fff"}
            _hover={
              !isFormValid
                ? { bg: "", color: "" }
                : { bg: "#003f70", transition: "0.3s" }
            }
            disabled={!isFormValid}
            onClick={save}
          >
            Selecionar forma de pagamento
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
