import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Button,
  Image,
  Link as ChakraLink,
  HStack,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import UserStorage from "../../util/userStorage"

export default function PaymentConfirmedScreen() {
  const backTo = UserStorage.hasToken() ? "/area-logada" : "/busca"
  return (
    <Layout
      internal={UserStorage.hasToken()}
      header={{
        title: "Pagamento",
        backTo: backTo,
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        justifyContent="center"
        gap="1rem"
        m="auto 0"
      >
        <Flex alignContent="center" justifyContent="center">
          <Image
            padding="1.5rem"
            borderRadius="50%"
            bg="#68D391"
            src="/images/card-icon/Check.svg"
            alt="Ícone da confirmação"
          />
        </Flex>
        <Flex direction="column" gap="1rem">
          <Text
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
            textAlign="center"
          >
            Pagamento confirmado
          </Text>
          <Text
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
            textAlign="center"
          >
            Obrigado pelo seu pagamento da tarifa de pedágio. Seu pagamento foi
            confirmado e uma confirmação foi enviada para você por e-mail.
          </Text>
          {!UserStorage.hasToken() && (
            <HStack
              bg="#FED7D7"
              p="0.625rem 2rem 0.625rem 0.75rem"
              borderRadius="0.5rem"
              display="flex"
              gap="0.625rem"
              alignItems="flex-start"
            >
              <Image src="/images/RedWarningV2.png" alt="Ícone de atenção" />
              <Text
                color="#2D3748"
                fontSize="0.75rem"
                fontWeight="400"
                fontFamily="Raleway"
              >
                <strong>Atenção,</strong> o pagamento anônimo impossibilita o
                contato futuro da CSG. Em caso de dúvidas entre em contato
                conosco através do e-mail <strong>cac@csg.com.br</strong> ou{" "}
                <strong>0800-122-0240 (opção 2).</strong>
              </Text>
            </HStack>
          )}
        </Flex>
      </Flex>
      {!UserStorage.hasToken() && (
        <Flex
          w="100%"
          maxWidth="515px"
          minH="100px"
          justifyContent="center"
          alignItems="flex-end"
        >
          <ChakraLink w="full" as={ReactRouterLink} to="/cadastrar">
            <Button
              w="full"
              size="lg"
              fontSize="1rem"
              bg="#00335B"
              color="white"
              _hover={{ bg: "#003f70", transition: "0.3s" }}
            >
              Realize o seu cadastro e evite multas
            </Button>
          </ChakraLink>
        </Flex>
      )}
    </Layout>
  )
}
