import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"
import { Vehicle } from "../../entities"

interface PaymentVerticalCardProps {
  vehicle: Vehicle
}

export default function PaymentVerticalCard({ vehicle }: PaymentVerticalCardProps) {
  return (
    <Flex
      w="105px"
      maxWidth="105px"
      h="142px"
      maxHeight="142px"
      bg="#fff"
      border="1px solid #E2E8F0"
      borderRadius="0.5rem"
      p="0.75rem"
    >
      <Flex
        w="full"
        h="full"
        gap="0.875rem"
        direction="column"
        alignItems="center"
      >
        <Flex
          // bg={`${props.status}`}
          p="0.750rem"
          borderRadius="0.5rem"
          fontSize="1.5rem"
          // color={`${props.statusBold}`}
        >
          <PiCar />
        </Flex>
        <Flex direction="column" alignItems="center">
          <Text
            textAlign="center"
            fontSize="0.875rem"
            fontWeight="600"
            color="#2D3748"
            fontFamily="Raleway"
          >
            {vehicle.manufacturer} {vehicle.model}
          </Text>
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#8E99AB"
            fontFamily="Raleway"
          >
            {vehicle.licensePlate}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
