import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"

interface PaymentCardProps {
  id: number
  licensePlate: string
  manufacturer: string
  model: string
  status: string
  statusBold: string
}

export default function PaymentCard(props: PaymentCardProps) {
  return (
    <Flex
      w="full"
      border="1px solid #E2E8F0"
      borderRadius="0.5rem"
      p="0.5rem 1rem"
    >
      <Flex w="full" gap="2">
        <Flex
          bg={`${props.status}`}
          p="0.750rem"
          borderRadius="0.5rem"
          alignItems="center"
          fontSize="1.5rem"
          color={`${props.statusBold}`}
        >
          <PiCar />
        </Flex>
        <Flex direction="column" alignItems="flex-start">
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#2D3748"
            fontFamily="Raleway"
          >
            {props.manufacturer} {props.model}
          </Text>
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#8E99AB"
            fontFamily="Raleway"
          >
            {props.licensePlate}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
