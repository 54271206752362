import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Flex, Text, HStack, Divider, Button, useToast } from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"
import CheckboxCard from "../../components/checkboxCard/CheckboxCard"
import {
  Charge,
  OrderSimulation,
  OrderSimulationInput,
  Vehicle,
} from "../../entities/csg"
import { ChargeService, OrderService, VehicleService } from "../../services/csg"
import Formatter from "../../util/formatter"
import { CheckoutInput } from "../../entities/csg/CheckoutInput"
import CheckoutStorage from "../../util/checkoutStorage"
import UserStorage from "../../util/userStorage"
import { SearchParams } from "../../entities"
import Loader from "../../components/Loader/Loader"

export default function PaymentDetails() {
  const navigate = useNavigate()
  const toast = useToast()
  const [searchParams] = useSearchParams()

  const [licensePlate, setLicensePlate] = useState<string>("")
  const [charges, setCharges] = useState<Charge[]>([])
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [orderSimulation, setOrderSimulation] = useState<OrderSimulation>(
    new OrderSimulation()
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isAnonymous, setIsAnonymous] = useState(true)

  useEffect(() => {
    if (searchParams.has("licensePlate")) {
      const licensePlate = searchParams.get("licensePlate") || ""
      setLicensePlate(licensePlate)
      load(licensePlate)
    } else if (UserStorage.hasToken()) {
      loadByUser()
    }
    // eslint-disable-next-line
  }, [searchParams])

  useEffect(() => {
    // if (licensePlate)
    loadSummary()

    console.log("aqui")
    // eslint-disable-next-line
  }, [selectedIds])

  const load = (licensePlate: string) => {
    setIsLoading(true)
    ChargeService.searchByPlate(licensePlate)
      .then((result) => {
        const charges = result.data

        if (charges.length === 0) {
          navigate("/busca?licensePlate=" + licensePlate)
          return
        }

        setCharges(charges)
        setSelectedIds(charges.map((charge) => charge.id))
      })
      .catch((error) => {
        setIsLoading(false)
        if (error?.response?.status === 403) {
          UserStorage.logout()

          const message: string = error?.response?.data?.message || ""
          if (message.includes("USER")) {
            navigate("/login/bloqueio/usuario")
          } else {
            navigate("/login/bloqueio/ip")
          }
        }
      })
      .finally(() => setIsLoading(false))
  }

  const loadByUser = async () => {
    const vehiclesResult = await VehicleService.search(
      new SearchParams([], 1, 9999)
    )
    const vehicles = vehiclesResult.data.filter(
      (vehicle) => vehicle.isActive === true && vehicle.isRegular === true
    )
    const licensePlates = vehicles.map((vehicle) => vehicle.licensePlate)
    setVehicles(vehicles)
    console.log("vehicles", vehicles)
    console.log("plates", licensePlates)

    setIsAnonymous(false)

    setIsLoading(true)
    ChargeService.searchByPlates(licensePlates, "open")
      .then((result) => {
        console.log("result", result)
        const charges = result.data
        setCharges(charges)
        setSelectedIds(charges.map((charge) => charge.id))
      })
      .finally(() => setIsLoading(false))
  }

  const loadSummary = () => {
    if (selectedIds.length === 0) {
      setOrderSimulation(new OrderSimulation())
      return
    }

    const input = new OrderSimulationInput({
      chargeIds: selectedIds,
    })

    if (searchParams.has("licensePlate")) {
      const licensePlate = searchParams.get("licensePlate") || ""
      input.licensePlates = [licensePlate]
    } else {
      input.licensePlates = vehicles.map((vehicle) => vehicle.licensePlate)
    }

    setIsLoading(true)
    OrderService.simulate(input)
      .then((simulation) => {
        setOrderSimulation(simulation)
      })
      .finally(() => setIsLoading(false))
  }

  const save = () => {
    if (selectedIds.length === 0) {
      alert("Você precisa escolher pelo menos um item.")
      return
    }

    const input = new CheckoutInput({
      chargeIds: selectedIds,
    })

    let to = "/checkout/info"
    if (searchParams.has("licensePlate")) {
      input.licensePlates = [licensePlate]
    } else if (UserStorage.hasToken()) {
      const user = UserStorage.get()
      input.licensePlates = vehicles.map((vehicle) => vehicle.licensePlate)
      input.name = user?.firstname + " " + user?.lastname
      input.email = user?.email || ""
      input.phone = user?.phone || ""
      to = "/checkout/pagamento"
    }

    CheckoutStorage.set(input)

    navigate(to)
  }

  const handleCheckboxChange = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedIds([...selectedIds, id])
    } else {
      const ids = [...selectedIds]
      const idx = ids.findIndex((item) => item === id)
      if (idx >= 0) {
        ids.splice(idx, 1)
        setSelectedIds(ids)
      }
    }
  }

  const selectAll = () => {
    const ids = charges.map((charge) => charge.id)
    setSelectedIds(ids)
  }

  return (
    <Layout
      internal={UserStorage.hasToken()}
      menuSelected={"vehicle"}
      header={{
        title: "Pagamento",
        backTo: `/busca`,
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        {!isAnonymous && (
          <>
            <Text fontSize="1rem" fontWeight="700" color="#2D3748">
              Selecione as cobranças que deseja pagar
            </Text>

            <Flex w="100%" direction="column">
              <Button
                p="0"
                m="0"
                alignSelf="flex-start"
                size="sm"
                bg="none"
                _hover={{ bg: "none" }}
                fontSize="0.875rem"
                color="#3531C6"
                fontWeight="700"
                onClick={selectAll}
              >
                Selecionar todas
              </Button>
              <Flex w="100%" gap="0.375rem" direction="column">
                {charges.map((charge) => (
                  <CheckboxCard
                    id={charge.id}
                    licensePlate={charge.licensePlate}
                    info={charge.plazaDescription}
                    status={charge.status}
                    statusCode={charge.status}
                    value={Formatter.formatChargeTotal(charge)}
                    date={Formatter.formatDateTime(charge.datetimeOccurrence)}
                    dueDate={Formatter.formatDate(charge.dueDate)}
                    checked={
                      selectedIds.findIndex((id) => id === charge.id) >= 0
                    }
                    onChange={handleCheckboxChange}
                    key={charge.id}
                  />
                ))}
              </Flex>
            </Flex>
          </>
        )}
        <Flex
          mt={2}
          mb={2}
          bg="#E2E8F080"
          p="0.75rem"
          direction="column"
          borderRadius="0.8rem"
        >
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Passagens que serão pagas
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              {selectedIds.length}
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Total da cobrança
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              {Formatter.formatCurrency(orderSimulation.rateAmount)}
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Descontos
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              {Formatter.formatCurrency(orderSimulation.discount)}
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Acréscimos
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              {Formatter.formatCurrency(orderSimulation.fee)}
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#2D3748" fontSize="0.875rem" fontWeight="700">
              Total a pagar
            </Text>
            <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
              {Formatter.formatCurrency(orderSimulation.total)}
            </Text>
          </HStack>
        </Flex>
        <Flex w="100%">
          <Button
            w="100%"
            size="lg"
            fontSize="1rem"
            variant="solid"
            bg={selectedIds.length < 0 ? "#E2E8F0" : "#00335B"}
            color={selectedIds.length < 0 ? "#00335B" : "#fff"}
            _hover={
              selectedIds.length < 0
                ? { bg: "", color: "" }
                : { bg: "#003f70", transition: "0.3s" }
            }
            onClick={save}
            disabled={selectedIds.length < 0}
          >
            Pagar agora
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
