import { User } from "../user"
import { VehicleTransfer } from "./VehicleTransfer"

export class Vehicle {
  public id: number
  public user: User | undefined
  public licensePlate: string
  public manufacturer: string
  public model: string
  public color: string
  public isRegular: boolean
  public isActive: boolean  
  public createdAt: Date|null
  public updatedAt: Date|null

  public transfers: VehicleTransfer[] = []

  public constructor(data: any = {}) {
    this.id = data.id || 0

    if (data.user) {
      this.user = new User(data.user)
    }

    this.licensePlate = data.licensePlate || ''
    this.manufacturer = data.manufacturer || ''
    this.model = data.model || ''
    this.color = data.color || ''
    this.isRegular = data.isRegular === true
    this.isActive = data.isActive === true

    this.createdAt = data.createdAt ? new Date(data.createdAt) : null
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null

    if (data.transfers) {
      for (const transfer of data.transfers) {
        this.transfers.push(new VehicleTransfer(transfer))
      }
    }
  }
}
