import React, { useCallback, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import VehicleSliderCard from "./card/VehicleSliderCard"
import { Flex } from "@chakra-ui/react"

import "swiper/css"
import VehicleBgSimpleVerticalCard from "../paymentCard/VehicleBgSimpleVerticalCard"
import VehicleBgSimpleCard from "../paymentCard/VehicleBgSimpleCard"

import "./VehicleSlider.scss"
import { Vehicle } from "../../entities"

interface VehicleSliderProps {
  vehicles: Vehicle[]
  zIndex?: number
  onSelectedVehicle: (plate: string | null) => void;
}

export default function VehicleSlider({ vehicles, onSelectedVehicle }: VehicleSliderProps) {
  const [selected, setSelected] = useState<number>(0)
  const handleSelectCard = useCallback((vehicle: Vehicle) => {
    onSelectedVehicle(vehicle.licensePlate)
    setSelected(vehicle.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ([]))
  const handleResetCard = useCallback(() => {
    onSelectedVehicle(null)
    setSelected(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex className="vehicle-slider-container">
      {vehicles && vehicles.length > 1 && (
        <Swiper spaceBetween={0} slidesPerView={3}>
          <SwiperSlide>
            <VehicleBgSimpleVerticalCard selected={selected} onReset={handleResetCard} />
          </SwiperSlide>
          {vehicles.map((vehicle: any) => (
            <SwiperSlide key={`vehicle-${vehicle.id}`}>
              <VehicleSliderCard vehicle={vehicle} selected={vehicle.id === selected} onSelectedCard={handleSelectCard} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {vehicles && vehicles.length === 1 && (
        <VehicleBgSimpleCard
          id={vehicles[0].id}
          licensePlate={vehicles[0].licensePlate}
          manufacturer={vehicles[0].manufacturer}
          model={vehicles[0].model}
        />
      )}
    </Flex>
  )
}
