import React from "react"
import { Flex, Text } from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"

interface VehicleBgSimpleCardProps {
  id: number
  licensePlate: string
  manufacturer: string
  model: string
}

export default function VehicleBgSimpleCard(props: VehicleBgSimpleCardProps) {
  return (
    <Flex
      w="full"
      maxWidth="323px"
      m="auto"
      bg="#F26522"
      border="1px solid #F26522"
      borderRadius="0.5rem"
      p="0.5rem 1rem"
    >
      <Flex w="full" gap="2">
        <Flex
          bg="#FCE0D3"
          p="0.750rem"
          borderRadius="0.5rem"
          alignItems="center"
          fontSize="1.5rem"
          color="#E44C00"
        >
          <PiCar />
        </Flex>
        <Flex direction="column" alignItems="flex-start">
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            fontFamily="Raleway"
          >
            {props.manufacturer} {props.model}
          </Text>
          <Text
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            fontFamily="Raleway"
          >
            {props.licensePlate}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
