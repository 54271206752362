import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
} from '@chakra-ui/react'
import Terms from './Terms'
import Politics from './Politics'

interface Props {
  title: string
  url: string
  isOpen?: any
  onOpen?: any
  onClose?: any
  type?: string
}

export default function TermsModal(props: Props) {
  const type = props.type || 'pdf'
  console.log('type', type)

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {type === 'terms' && <Terms />}
            {type === 'politics' && <Politics />}
            {type === 'pdf' && (
            <embed src={props.url} width="100%" height="600" type="application/pdf" />           
            )}
            {type === 'iframe' && (
            // <iframe src={props.url} width="100%" height="600" />
            <object data={props.url} width="800" height="500">
               Parece que você não tem um visualizador de PDF no seu navegador.
            </object>
            )}
            <Flex alignContent="center" marginTop="20px">
              <Button margin="auto" onClick={props.onClose}>Fechar</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}