import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { PiCar, PiTrashLight, PiWarningFill } from "react-icons/pi"

interface VehicleProps {
  id: number
  licensePlate: string
  manufacturer: string
  model: string
  status: string
  statusBold: string
  isRegular: boolean
  requested: boolean
  onDelete?: any
}

export default function VehicleCard(props: VehicleProps) {
  console.log(props)
  const deleteVehicle = () => {
    if (props.onDelete) {
      props.onDelete(props.id)
    }
  }
  return (
    <Accordion
      allowToggle
      w="full"
      border="1px solid #E2E8F0"
      borderRadius="0.5rem"
      overflow="hidden"
      defaultIndex={ !props.isRegular ? [0] : undefined }
      bg={!props.isRegular ? "#FED7D7" : "transparent"}
    >
      <AccordionItem border="none">
        <h2>
          <AccordionButton
            _expanded={
              !props.isRegular
                ? { bg: "#FED7D7" }
                : { bg: "transparent" }
            }
            border="none"
          >
            <Flex w="full" justifyContent="space-between">
              <Flex gap="2">
                <Flex
                  bg={`${props.status}`}
                  p="0.750rem"
                  borderRadius="0.5rem"
                  alignItems="center"
                  fontSize="1.5rem"
                  color={`${props.statusBold}`}
                >
                  <PiCar />
                </Flex>
                <Flex direction="column" alignItems="flex-start">
                  <Text
                    fontSize="0.875rem"
                    fontWeight="600"
                    color="#2D3748"
                    fontFamily="Raleway"
                  >
                    {props.licensePlate}
                  </Text>
                  <Text
                    fontSize="0.875rem"
                    fontWeight="600"
                    color="#8E99AB"
                    fontFamily="Raleway"
                  >
                    {props.manufacturer} {props.model}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                p="0.750rem"
                alignItems="center"
                fontSize="1.3rem"
                color="#8E99AB"
                onClick={deleteVehicle}
              >
                <PiTrashLight />
              </Flex>
            </Flex>
          </AccordionButton>
        </h2>
        {!props.isRegular && (
          <AccordionPanel bg="#FED7D7">
            <Flex w="full" direction="column" gap="3">
              <Flex bg="#FFF5F580" p="0.75rem 0.625rem" gap="2">
                <Flex
                  alignItems="flex-star"
                  color="#E53E3E"
                  fontSize="1.25rem"
                  mt="1"
                >
                  <PiWarningFill />
                </Flex>
                {props.requested === false && (
                  <Flex direction="column" gap="2">
                    <Text fontSize="0.875rem" fontWeight="700" color="#2D3748">
                      Esse veículo está associado a outra conta.
                    </Text>
                    <Text fontSize="0.75rem" fontWeight="500" color="#4A5568">
                      Confirme os dados inseridos. Se for o proprietário,
                      escolha 'Abrir Ticket' e siga as orientações para
                      proceder.
                    </Text>
                  </Flex>
                )}
                {props.requested === true && (
                  <Flex alignItems="center">
                    <Text fontSize="0.875rem" fontWeight="700" color="#2D3748">
                      Solicitação enviada.
                    </Text>
                  </Flex>
                )}
              </Flex>
              {props.requested === false && (
                <Flex gap="1.5rem" w="full">
                  <Button
                    w="50%"
                    size="sm"
                    variant="outline"
                    fontSize="0.875rem"
                    fontWeight="600"
                    color="#1A202C"
                    borderColor="#FEB2B2"
                    _focus={{
                      bg: "#F7F8F9",
                      color: "#1A202C",
                      borderColor: "transparent",
                    }}
                    onClick={() => {}}
                  >
                    Remover
                  </Button>
                  <ChakraLink
                    w="50%"
                    as={ReactRouterLink}
                    to={`/veiculo/solicitacao/${props.id}`}
                  >
                    <Button
                      w="100%"
                      size="sm"
                      variant="outline"
                      fontSize="0.875rem"
                      fontWeight="600"
                      color="#1A202C"
                      borderColor="#FEB2B2"
                      _focus={{
                        bg: "#F7F8F9",
                        color: "#1A202C",
                        borderColor: "transparent",
                      }}
                    >
                      Abrir solicitação
                    </Button>
                  </ChakraLink>
                </Flex>
              )}
            </Flex>
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  )
}
