export class VehicleTransferDocument {
  public id: number
  public name: string
  public originalname: string
  public path: string
  public createdAt: Date

  public constructor(data: any) {
    this.id = data.id || 0
    this.name = data.name || ''
    this.originalname = data.originalname || ''
    this.path = data.path || ''
    this.createdAt = new Date(data.createdAt)
  }

}