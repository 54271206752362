import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Image, Link as ChakraLink } from "@chakra-ui/react"

export default function SplashScreen() {
  return (
    <Flex minH="100vh" bg="#00335B" alignItems="center" justifyContent="center">
      <ChakraLink
        mb="10rem"
        as={ReactRouterLink}
        target="_blank"
        to="http://site.dev.csg.attri.com.br/"
      >
        <Image src="/images/csg-logo-white.png" alt="Logo branco da CSG" />
      </ChakraLink>
    </Flex>
  )
}
