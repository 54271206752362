import { VehicleTransfer, VehicleTransferDocument } from '../../entities'
import { VehicleTransferRepository } from '../../repositories/csg'
import UserStorage from '../../util/userStorage'


export class VehicleTransferService {
  public static async uploadDocument(formData: FormData): Promise<VehicleTransferDocument> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.uploadDocument(formData, token)
  }

  public static async create(transfer: VehicleTransfer): Promise<VehicleTransfer> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.create(transfer, token)
  }
}