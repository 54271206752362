import { VehicleTransfer, VehicleTransferDocument } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class VehicleTransferRepository {

  public static async uploadDocument(formData: FormData, token: string): Promise<VehicleTransferDocument> {
    const headers: any = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
    }
    
    const response = await client.DoRequest('POST', '/vehicleTransfer/uploadDocument', formData, headers)

    return new VehicleTransferDocument(response)
  }

  public static async create(transfer: VehicleTransfer, token: string): Promise<VehicleTransfer> {
    const data = {...transfer, vehicleId: transfer.vehicle.id}
    const headers = { Authorization: `Bearer ${token}`}
    const response = await client.DoRequest("POST", "/vehicleTransfer", data, headers)

    return new VehicleTransfer(response)
  }

}