export class OrderCard {
  public number: string
  public holderName: string
  public expirationMonth: number
  public expirationYear: number
  public cvv: number

  public constructor(data: any = {}) {
    this.number = data.number || ''
    this.holderName = data.holderName || ''
    this.expirationMonth = data.expirationMonth ? parseInt(data.expirationMonth) : 0
    this.expirationYear = data.expirationYear ? parseInt(data.expirationYear) : 0
    this.cvv = data.cvv ? parseInt(data.cvv) : 0
  }
}