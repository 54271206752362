import { ContactRepository } from "../../repositories/csg";
import UserStorage from "../../util/userStorage";

export class ContactService {
  public static async contact(data: any): Promise<void> {
    await ContactRepository.contact(data)
  }

  public static async requestDeleteAccount(data: any): Promise<void> {
    const token = UserStorage.getToken()
    await ContactRepository.requestDeleteAccount(data, token)
  }
}