import { CheckoutInput, Order, OrderSimulation, OrderSimulationInput } from "../../entities/csg"
import { OrderRepository } from "../../repositories/csg"
import UserStorage from "../../util/userStorage"

export class OrderService {
  public static async checkout(input: CheckoutInput): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.checkout(input, token)
  }

  public static async simulate(
    input: OrderSimulationInput
  ): Promise<OrderSimulation> {
    const token = UserStorage.getToken()
    return await OrderRepository.simulate(input, token)
  }

  public static async get(id: number, validator: number): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.get(id, validator, token)
  }

  public static async getStatus(id: number, validator: number): Promise<string> {
    const token = UserStorage.getToken()
    return await OrderRepository.getStatus(id, validator, token)
  }
}
