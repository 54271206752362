import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Text, Button, Link as ChakraLink, Image } from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"

export default function RedefinePasswordSuccess() {
  return (
    <Layout
      internal={false}
      header={{ title: "", backTo: "/login", tip: "Dúvidas acesse nosso site" }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        justifyContent="center"
        gap="1rem"
        m="auto 0"
      >
        <Flex justifyContent="center" alignItems="center">
          <Image
            src="/images/SuccessIcon.png"
            alt="Senha redefinida com sucesso"
          />
        </Flex>
        <Flex direction="column" alignItems="center">
          <Text
            textAlign="center"
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
          >
            Senha redefinida
          </Text>
          <Text
            textAlign="center"
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
          >
            Você redefiniu sua senha com sucesso!
          </Text>
          <Text
            textAlign="center"
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
          >
            Faça login para acessar a sua conta
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <ChakraLink w="full" as={ReactRouterLink} to="/login">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            bg="#00335B"
            color="white"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
          >
            Fazer login
          </Button>
        </ChakraLink>
      </Flex>
    </Layout>
  )
}
