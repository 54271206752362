import { SearchParams, SearchResult } from "../../entities"
import { Vehicle } from "../../entities/csg"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class VehicleRepository {
  public static async searchByPlate(
    licensePlate: string,
    token: string
  ): Promise<SearchResult<Vehicle>> {
    const headers = { Authorization: `Bearer ${token}` }

    const url = `/vehicle?licensePlate=${licensePlate}`

    const response = await client.DoRequest("GET", url, {}, headers)

    const result = new SearchResult<Vehicle>(
      response.data,
      response.info
    )

    return result
  }

  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<Vehicle>> {
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/vehicle?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<Vehicle>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<Vehicle> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/vehicle/${id}`, {}, headers)

    return new Vehicle(response)
  }


  public static async create(vehicle: Vehicle, token: string): Promise<Vehicle> {
    const headers = { Authorization: `Bearer ${token}`}
    const response = await client.DoRequest("POST", "/vehicle", vehicle, headers)

    return new Vehicle(response)
  }

  public static async delete(id: number, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("DELETE", `/vehicle/${id}`, {}, headers)

    return response === true
  }

  
}
