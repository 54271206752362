import { User, SearchParams, SearchResult } from "../../entities"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class UserRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<User>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/user?page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<User>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/user/${id}`, {}, headers)

    return new User(response)
  }

  public static async create(user: User): Promise<User> {
    const response = await client.DoRequest("POST", "/user", user)

    return new User(response)
  }

  public static async update(user: User, token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/user/${user.id}`,
      user,
      headers
    )

    console.log('resp', response)

    return new User(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "DELETE",
      `/user/${id}`,
      {},
      headers
    )

    return response === true
  }

  public static async getAuthenticatedUser(token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", "/user/profile", {}, headers)

    if (response.status === 200) {
      return new User(response.data)
    } else {
      throw new Error(
        "Erro ao obter usuário autenticado. Status: " + response.status
      )
    }
  }

  public static async changePassword(password: string, newPassword: string, newPasswordConfirm: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}`}
    const data = { password, newPassword, newPasswordConfirm }
    const res = await client.DoRequest('POST', '/user/changePassword', data, headers)

    return res === true
  }

  public static async updateOneSignalToken(oneSignalToken: string, token: string): Promise<void> {
    try {
      const headers = { Authorization: `Bearer ${token}`}
      const data: any = { oneSignalToken }
      const res = await client.DoRequest('POST', '/user/updateOneSignalToken', data, headers)
      return res.token
    } catch (error: any) {
      console.log('error', error)
    }
  }
}
