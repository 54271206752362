import { BalanceReturn } from "../../entities/csg/BalanceReturn"
import { BalanceReturnRepository } from "../../repositories/csg/BalanceReturnRepository"
import UserStorage from "../../util/userStorage"

export class BalanceReturnService {

  public static async create(balanceReturn: BalanceReturn): Promise<BalanceReturn> {
    const token = UserStorage.getToken()
    return await BalanceReturnRepository.create(balanceReturn, token)
  }

}