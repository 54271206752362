import { SearchResultInfo } from "./SearchResultInfo"

export class SearchResult<A> {
  public data: A[]
  public info: SearchResultInfo

  public constructor(data: A[], info: any) {
    this.data = data
    this.info = new SearchResultInfo(info)
  }
}