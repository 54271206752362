import React from "react"
import { Flex, Text, Image } from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout/Layout"

export default function RedeemBalanceWaiting() {
  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Resgatar saldo",
        backTo: "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        justifyContent="center"
        gap="1rem"
        m="auto 0"
      >
        <Flex alignContent="center" justifyContent="center">
          <Image
            padding="1.5rem"
            borderRadius="50%"
            bg="#7694FF"
            src="/images/card-icon/HourglassWhite.png"
            alt="Ícone do aguardo"
          />
        </Flex>
        <Flex direction="column" gap="1rem">
          <Text
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
            textAlign="center"
          >
            Processando resgate
          </Text>
          <Text
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
            textAlign="center"
          >
            O resgate pode levar até 3 dias úteis para ser depositado. Assim que
            estiver confirmado, você receberá um SMS ou poderá consultar
            novamente para verificar.
          </Text>
        </Flex>
      </Flex>
    </Layout>
  )
}
