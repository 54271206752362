import React, { useEffect, useState } from "react"
import {
  Flex,
  Text,
  FormControl,
  Input,
  ButtonGroup,
  Button,
  VStack,
  HStack,
  Image,
  useToast,
} from "@chakra-ui/react"
import PaymentTypeCreditCard from "../../components/paymentCard/PaymentTypeCreditCard"
import PaymentTypePix from "../../components/paymentCard/PaymentTypePix"
import Layout from "../../components/layout/Layout/Layout"
import { BankAccount, CheckoutCardInput, CheckoutInput } from "../../entities"
import { useNavigate } from "react-router-dom"
import { UserService } from "../../services"
import { BankAccountService } from "../../services/csg/BankAccount"
import Formatter from "../../util/formatter"
import { OrderService } from "../../services/csg"
import Loader from "../../components/Loader/Loader"
import UserStorage from "../../util/userStorage"

export default function AddBalance() {
  const navigate = useNavigate()
  const toast = useToast()

  const [amount, setAmount] = useState("")
  const [bankAccount, setBankAccount] = useState<BankAccount>(new BankAccount())

  const [isLoading, setIsLoading] = useState(false)

  const checkout = new CheckoutInput()

  useEffect(() => {
    setIsLoading(true)
    UserService.isValidUser()
      .then((isValid) => {
        if (!isValid) {
          UserStorage.logout()
          navigate("/login")
          return
        }
        loadBankAccount()
      })
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newAmount = parseFloat(amount || "0")
    console.log(newAmount)
    if (newAmount > 1000) {
      toast({
        title: `Você pode colocar até R$ 1.000,00 de crédito!`,
        status: "warning",
        isClosable: true,
        duration: 2000,
        position: "top",
      })
      setAmount("1000")
    }
  }, [amount])

  const loadBankAccount = () => {
    setIsLoading(true)
    BankAccountService.getByUser()
      .then((bankAccount) => setBankAccount(bankAccount))
      .finally(() => setIsLoading(false))
  }

  const addAmount = (value: number) => {
    const newAmount = parseFloat(amount || "0") + value
    setAmount(newAmount.toFixed(2))
  }

  const payByPix = () => {
    checkout.paymentMethodCode = "pix"

    const to = "/detalhes-de-pagamentos/pix"
    pay(checkout, to)
  }

  const payByCard = (card: CheckoutCardInput, token: string) => {
    checkout.paymentMethodCode = "credit-card"
    checkout.card = card
    checkout.token = token

    const to = "/detalhes-de-pagamentos/confirmado"
    pay(checkout, to)
  }

  const pay = (input: CheckoutInput, to = "") => {
    if (parseFloat(amount || "0") < 15) {
      toast({
        title: "O valor mínimo é R$ 15,00.",
        status: "error",
      })
      return
    }

    input.type = "balance"
    input.amount = parseFloat(amount) * 100

    setIsLoading(true)
    OrderService.checkout(input)
      .then((order) => {
        console.log("order", order)
        navigate(`${to}/${order.id}?validator=${order.validator}`)
      })
      .catch((error) =>
        toast({
          title: error?.response?.data?.message,
          status: "error",
        })
      )
      .finally(() => setIsLoading(false))

    // navigate(`${to}/1?validator=123456`)
  }

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Adicionar saldo",
        backTo: "/area-logada",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <VStack>
          <Text
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Saldo a ser adicionado
          </Text>
          <Text
            fontSize="0.750rem"
            fontWeight="400"
            color="#8E99AB"
            alignSelf="flex-start"
          >
            Movimentação mínima: R$ 15,00
          </Text>
          <FormControl isRequired>
            <Input
              size="lg"
              type="number"
              value={amount}
              onChange={(event) => setAmount(event.target.value)}
              placeholder="R$ 0,00"
            />
          </FormControl>
          <ButtonGroup display="flex" justifyContent="space-between" w="100%">
            <Button
              w="33%"
              variant="solid"
              colorScheme="gray"
              color="#1A202C"
              fontSize="0.875rem"
              fontWeight="600"
              p="0 1.20rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => addAmount(50)}
            >
              + R$50,00
            </Button>
            <Button
              w="33%"
              variant="solid"
              colorScheme="gray"
              color="#1A202C"
              fontSize="0.875rem"
              fontWeight="600"
              p="0 1.20rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => addAmount(100)}
            >
              + R$100,00
            </Button>
            <Button
              w="33%"
              variant="solid"
              colorScheme="gray"
              color="#1A202C"
              fontSize="0.875rem"
              fontWeight="600"
              p="0 1.20rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => addAmount(150)}
            >
              + R$150,00
            </Button>
          </ButtonGroup>
          <Text
            fontSize="0.750rem"
            fontWeight="400"
            color="#8E99AB"
            alignSelf="flex-start"
          >
            Saldo após a adição:{" "}
            {Formatter.formatCurrency(
              bankAccount.balance + parseFloat(amount || "0") * 100
            )}
          </Text>
          <Text
            fontSize="0.750rem"
            fontWeight="400"
            color="#8E99AB"
            alignSelf="flex-start"
          >
            Com o app da CSG seu saldo não expira. Você pode resgatar o saldo
            quando quiser.
          </Text>
        </VStack>
        <VStack w="full">
          <Text
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Forma de Pagamento
          </Text>
          <PaymentTypePix pay={payByPix} />
          <PaymentTypeCreditCard pay={payByCard} />
        </VStack>
        <HStack
          bg="#FFFAF0"
          p="0.625rem 2rem 0.625rem 0.75rem"
          borderRadius="0.5rem"
          display="flex"
          gap="0.625rem"
          alignItems="center"
          color="red"
        >
          <Image
            alignSelf="flex-start"
            src="/images/WarningYellowIcon.png"
            alt="Ícone de atenção"
          />
          <Flex direction="column" gap="0.25rem">
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              Atenção: O pagamento do pedágio depende do seu saldo no app. Se
              você tiver saldo suficiente na hora da cobrança, o pagamento é
              feito automaticamente. <br />
            </Text>
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              {" "}
              Se você não tiver saldo, você terá que pagar manualmente com
              cartão de crédito ou PIX. Nesse caso, adicionar saldo depois não
              vai resolver. Você terá que pagar cada cobrança manualmente.
            </Text>
          </Flex>
        </HStack>
        <HStack
          bg="rgba(231, 236, 255, 0.50)"
          p="0.625rem 2rem 0.625rem 0.75rem"
          borderRadius="0.5rem"
          display="flex"
          gap="0.625rem"
          alignItems="center"
        >
          <Image
            src="/images/card-icon/InfoBlue.svg"
            alt="Ícone de informação"
          />
          <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
            Evite multas. Cobranças vencidas com mais de 15 dias podem ter
            acréscimos. Tenha sempre saldo na sua conta
          </Text>
        </HStack>
      </Flex>
    </Layout>
  )
}
