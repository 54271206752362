import React from "react"
import { Flex, Box, Text, Spinner } from "@chakra-ui/react"

export default function LoadingScreen() {
  return (
    <Flex
      minH="100vh"
      bg="rgb(240, 240, 240)"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        minW="100vw"
        minH="100vh"
        maxH="100vh"
        opacity="0.9"
        zIndex="999"
        position="fixed"
        top="0"
        left="0"
        bg="rgb(240, 240, 240)"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          color="rgb(220, 220, 220)"
          position="fixed"
          zIndex="998"
          fontSize="3.90rem"
          fontWeight="600"
          m="auto"
          fontFamily="Raleway"
        >
          CSG
        </Text>
        <Spinner
          zIndex="999"
          thickness="7px"
          speed="1s"
          emptyColor="gray.100"
          color="rgb(220, 220, 220)"
          size="xl"
        />
        {/* <Spinner
          zIndex="999"
          thickness="2px"
          speed="0.55s"
          emptyColor="gray.200"
          color="#00335B"
          size="xl"
        /> */}
      </Box>
    </Flex>
  )
}
