import { SearchParams, SearchResult } from "../../entities"
import { Vehicle } from "../../entities/csg"
import { VehicleRepository } from "../../repositories/csg"
import UserStorage from "../../util/userStorage"

export class VehicleService {
  public static async search(params: SearchParams): Promise<SearchResult<Vehicle>> {
    const token = UserStorage.getToken()
    return await VehicleRepository.search(params, token)
  }

  public static async get(id: number): Promise<Vehicle> {
    const token = UserStorage.getToken()
    return await VehicleRepository.get(id, token)
  }

  public static async create(vehicle: Vehicle): Promise<Vehicle> {
    const token = UserStorage.getToken()
    return await VehicleRepository.create(vehicle, token)
  }

  public static async delete(id: number): Promise<boolean> {
    const token = UserStorage.getToken()
    return await VehicleRepository.delete(id, token)
  }

}
