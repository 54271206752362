import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
    Flex,
  Text,
  Button,
  Image,
  Link as ChakraLink,
} from "@chakra-ui/react"
import Layout from "../../components/layout/Layout/Layout"

export default function PaymentWaitingScreen() {
  return (
    <Layout
      internal={false}
      header={{
        title: "Pagamento",
        backTo: "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        justifyContent="center"
        gap="1rem"
        m="auto 0"
      >
        <Flex alignContent="center" justifyContent="center">
          <Image src="/images/BigWaitingClockIcon.png" alt="Ícone do aguardo" />
        </Flex>
        <Flex direction="column" gap="1rem">
          <Text
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
            textAlign="center"
          >
            Aguardando confirmação de pagamento
          </Text>
          <Text
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
            textAlign="center"
          >
            Pode demorar até 3 dias úteis para que o pagamento seja confirmado.
            Assim que o pagamento for confirmado, enviaremos um SMS ou você
            poderá realizar uma nova consulta para verificar.
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <ChakraLink w="full" as={ReactRouterLink} to="/">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            bg="#00335B"
            color="white"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
          >
            Realizar nova consulta
          </Button>
        </ChakraLink>
      </Flex>
    </Layout>
  )
}
