import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Flex,
  Text,
  FormLabel,
  Button,
  VStack,
  Link as ChakraLink,
  useDisclosure,
} from "@chakra-ui/react"
import VehicleCard from "../../../components/vehicleCard/VehicleCard"
import { PiPlusBold } from "react-icons/pi"
import Layout from "../../../components/layout/Layout/Layout"
import { VehicleService } from "../../../services/csg"
import { SearchParams, Vehicle } from "../../../entities"
import { UserService } from "../../../services"
import UserStorage from "../../../util/userStorage"
import Loader from "../../../components/Loader/Loader"
import DeleteAccountModal from "../../../components/deleteAccount/DeleteAccountModal"

export default function VehicleList() {
  const navigate = useNavigate()

  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [idDelete, setIdDelete] = useState(0)

  const deleteVehicleDisclosure = useDisclosure()

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      load()
    })
    // eslint-disable-next-linehttps://react-select.com/home
  }, [])

  const load = () => {
    const params = new SearchParams([], 1, 9999)

    setIsLoading(true)
    VehicleService.search(params)
      .then((result) => {
        setVehicles(result.data.filter((vehicle) => vehicle.isActive === true))
      })
      .catch((error: any) => {
        console.log("error", error.message)
      })
      .finally(() => setIsLoading(false))
  }

  const deleteVehicle = () => {
    setIsLoading(true)
    VehicleService.delete(idDelete)
      .then((result) => {
        deleteVehicleDisclosure.onClose()
        load()
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={true}
      header={{
        title: "Adicionar veículo",
        backTo: "/area-logada",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
      menuSelected="vehicle"
    >
      {isLoading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        mb="2rem"
        direction="column"
        gap="1rem"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Adicionar veículos
          </Text>
        </Flex>
        <VStack>
          <FormLabel
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Veículos cadastrados
          </FormLabel>
        </VStack>
        <VStack gap="2">
          {vehicles.map((vehicle) => (
            <VehicleCard
              key={vehicle.id}
              id={vehicle.id}
              licensePlate={vehicle.licensePlate}
              manufacturer={vehicle.manufacturer}
              model={vehicle.model}
              status=""
              statusBold=""
              isRegular={vehicle.isRegular}
              requested={
                vehicle.transfers.findIndex(
                  (transfer) => transfer.status === "open"
                ) >= 0
              }
              onDelete={() => {
                setIdDelete(vehicle.id)
                deleteVehicleDisclosure.onOpen()
              }}
            />
          ))}
        </VStack>
        <ChakraLink w="100%" as={ReactRouterLink} to="/veiculo/form">
          <Button
            w="100%"
            size="md"
            fontSize="0.875rem"
            bg="#E2E8F0"
            color="#1A202C"
            display="flex"
            alignItems="center"
            gap="2"
            _hover={{ bg: "#d5d9df", transition: "0.3s" }}
          >
            <PiPlusBold /> Adicionar outro veículo
          </Button>
        </ChakraLink>
      </Flex>
      <DeleteAccountModal
        title="Remover Veículo"
        message="Deseja remover o veículo?"
        isOpen={deleteVehicleDisclosure.isOpen}
        onOpen={deleteVehicleDisclosure.onOpen}
        onClose={deleteVehicleDisclosure.onClose}
        action={deleteVehicle}
      />
    </Layout>
  )
}
