import React, { useState } from "react"
import { Flex, Text } from "@chakra-ui/react"
import { PiCar } from "react-icons/pi"

interface VehicleBgSimpleVerticalCardProps {
  selected: number
  onReset: () => void
}

export default function VehicleBgSimpleVerticalCard({selected, onReset} : VehicleBgSimpleVerticalCardProps) {
  return (
    <Flex
      w="105px"
      maxWidth="105px"
      h="142px"
      maxHeight="142px"
      bg={selected === 0 ? '#F26522' : '#fff'}
      border={selected === 0 ? '"1px solid #F26522"' : '1px solid #E2E8F0'}
      borderRadius="0.5rem"
      p="0.75rem"
    >
      <Flex
        w="full"
        h="full"
        gap="0.875rem"
        direction="column"
        alignItems="center"
      >
        <Flex
          bg={selected === 0 ? '#FCE0D3' : ''}
          p="0.750rem"
          borderRadius="0.5rem"
          fontSize="1.5rem"
          color={selected === 0 ? '#E44C00' : ''}
        >
          <PiCar />
        </Flex>
        <Flex alignItems="center" onClick={() => onReset()}>
          <Text
            textAlign="center"
            fontSize="0.875rem"
            fontWeight="600"
            color={selected === 0 ? '#fff' : '#2D3748'}
            fontFamily="Raleway"
          >
            Todos os meus veículos
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
