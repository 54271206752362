export default class QueryStringBuilder {
  private queryParts: string[] = []

  addParameter(key: string, value: string | number | undefined | null): void {
    if (value !== undefined && value !== null) {
      this.queryParts.push(`${key}=${encodeURIComponent(value)}`)
    }
  }

  buildQueryString(): string {
    return this.queryParts.join("&")
  }
}
