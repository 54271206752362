import { CheckoutInput, Order, OrderSimulation, OrderSimulationInput } from "../../entities/csg"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class OrderRepository {
  public static async checkout(input: CheckoutInput,token: string): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/order/checkout`,
      input,
      headers
    )

    console.log('resp', response)
    
    return new Order(response)
  }

  public static async simulate(
    input: OrderSimulationInput,
    token: string
  ): Promise<OrderSimulation> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/order/simulate`,
      input,
      headers
    )
    
    return new OrderSimulation(response)
  }

  public static async get(id: number, validator: number, token: string): Promise<Order> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "GET",
      `/order/${id}?validator=${validator}`,
      {},
      headers
    )
    
    return new Order(response)
  }

  public static async getStatus(id: number, validator: number, token: string): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "GET",
      `/order/status/${id}?validator=${validator}`,
      {},
      headers
    )
    
    return response
  }
}
