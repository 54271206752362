export class CheckoutCardInput {
  public number: string
  public holderName: string
  public expirationMonth: number
  public expirationYear: number
  public cvv: number

  public constructor(data: any = {}) {
    this.number = data.number || ""
    this.holderName = data.holderName || ""
    this.expirationMonth = data.expirationMonth
      ? parseInt(data.expirationMonth)
      : 0
    this.expirationYear = data.expirationYear
      ? parseInt(data.expirationYear)
      : 0
    this.cvv = data.cvv ? parseInt(data.cvv) : 0
  }
}

export class CheckoutInput {
  public type: string
  public licensePlates: string[]
  public chargeIds: number[]
  public amount: number

  public name: string
  public email: string
  public phone: string

  public paymentMethodCode: string

  public token: string | null

  public card: CheckoutCardInput | null

  public constructor(data: any = {}) {
    this.type = data.type || "charge"
    this.licensePlates = data.licensePlates || []
    this.chargeIds = data.chargeIds || []
    this.amount = data.amount || 0

    this.name = data.name || ""
    this.email = data.email || ""
    this.phone = data.phone || ""

    this.paymentMethodCode = data.paymentMethodCode || ""

    this.token = data.token || null

    this.card = data.card ? new CheckoutCardInput(data.card) : null
  }
}
