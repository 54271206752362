import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class ContactRepository {
  public static async contact(data: any): Promise<void> {
    await client.DoRequest("POST", "/contact", data)
  }

  public static async requestDeleteAccount(data: any, token: string): Promise<void> {
    const headers = { Authorization: `Bearer ${token}` }
    await client.DoRequest("POST", "/contact/deleteAccount", {}, headers)
  }
}