import { OrderCard } from "./OrderCard"
import { OrderPix } from "./OrderPix"
import { OrderTotals } from "./OrderTotals"

export class Order {
  public id: number
  public validator: number

  public type: string

  public licensePlates: string[]
  public chargeIds: number[]

  public name: string
  public email: string
  public phone: string

  public paymentMethodCode: string

  public status: string
  public createdAt: Date

  public totals: OrderTotals|null

  public card: OrderCard|null

  public pix: OrderPix|null

  public constructor(data: any = {}) {
    this.id = data.id || 0
    this.validator = data.validator || 0

    this.type = data.type || ''

    this.licensePlates = data.licensePlates || []
    this.chargeIds = data.chargeIds || []

    this.name = data.name || ''
    this.email = data.email || ''
    this.phone = data.phone || ''

    this.paymentMethodCode = data.paymentMethodCode || ''
    
    this.status = data.status || ''
    this.createdAt = new Date(data.createdAt)

    this.totals = data.totals ? new OrderTotals(data.totals) : null

    this.card = data.card ? new OrderCard(data.card) : null

    this.pix = data.pix ? new OrderPix(data.pix) : null
    
  }
}