import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Button,
  Image,
  Box,
  Link as ChakraLink,
  background,
} from "@chakra-ui/react"

interface FooterMenuProps {
  menuSelected?: string
}

export default function FooterMenu(props: FooterMenuProps) {
  const menuSelected = props.menuSelected || "home"

  return (
    <Flex
      position="sticky"
      bottom="0"
      borderRadius="1rem 1rem 0 0"
      boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
      w="100%"
      maxWidth="515px"
      h="80x"
      minH="80px"
      bg="#fff"
      fontFamily="Raleway"
      direction="column"
      alignItems="center"
      justifyContent="center"
      m="0 auto"
    >
      <Flex w="100%" h="100%" alignItems="center" justifyContent="space-around">
        <ChakraLink
          w="30%"
          as={ReactRouterLink}
          to="/area-logada"
          _hover={{ textDecoration: "none" }}
        >
          <Button
            w="100%"
            bg={menuSelected === "home" ? "#E7ECFFB2" : "#fff"}
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="600"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0.25rem"
            _hover={{ background: "#E7ECFFB2" }}
          >
            {menuSelected === "home" && (
              <Image
                src="/images/BlueFooterMenuFillHouseIcon.png"
                alt="Ícone da Homepage"
              />
            )}
            {menuSelected !== "home" && (
              <Image
                src="/images/BlueFooterMenuHouseIcon.png"
                alt="Ícone da Homepage"
              />
            )}
            Home
          </Button>
        </ChakraLink>
        <ChakraLink
          w="30%"
          as={ReactRouterLink}
          to="/veiculo"
          _hover={{ textDecoration: "none" }}
        >
          <Button
            w="100%"
            bg={menuSelected === "vehicle" ? "#E7ECFFB2" : "#fff"}
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="600"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0.25rem"
            _hover={{ background: "#E7ECFFB2" }}
          >
            {menuSelected === "vehicle" && (
              <Image
                src="/images/BlueFooterMenuFillCarIcon.png"
                alt="Ícone de um Veículo"
              />
            )}
            {menuSelected !== "vehicle" && (
              <Image
                src="/images/BlueFooterMenuCarIcon.png"
                alt="Ícone de um Veículo"
              />
            )}
            Veículos
          </Button>
        </ChakraLink>
        <ChakraLink
          w="30%"
          as={ReactRouterLink}
          to="/perfil"
          _hover={{ textDecoration: "none" }}
        >
          <Button
            w="100%"
            bg={menuSelected === "profile" ? "#E7ECFFB2" : "#fff"}
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="600"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="0.25rem"
            _hover={{ background: "#E7ECFFB2" }}
          >
            {menuSelected === "profile" && (
              <Image
                src="/images/BlueFooterMenuFillProfileIcon.png"
                alt="Ícone do Perfil"
              />
            )}
            {menuSelected !== "profile" && (
              <Image
                src="/images/BlueFooterMenuProfileIcon.png"
                alt="Ícone do Perfil"
              />
            )}
            Perfil
          </Button>
        </ChakraLink>
      </Flex>
      <Box
        w="100%"
        maxW="6.13rem"
        h="2px"
        bg="#A2ACBD"
        m="0.5rem auto 0.5rem auto"
        boxSizing="border-box"
      ></Box>
    </Flex>
  )
}
