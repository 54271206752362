import { User } from "../user"

export class Charge {
  public id: number
  public user: User | undefined
  public transactionId: number
  public financialTransactionId: number
  public datetimeAvailable: Date = new Date()
  public datetimeOccurrence: Date = new Date()
  public dueDate: Date = new Date()
  public isOverdue: boolean
  public licensePlate: string
  public tagIdentifier: string
  public vehicleClassRated: string
  public rateAmount: number
  public discount: number
  public discountDuf: number
  public discountDbt: number
  public fine: number
  public interest: number
  public fee: number
  public plazaCode: string
  public plazaDescription: string
  public laneCode: string
  public status: string
  public isReSynchorization: boolean
  public createdAt: Date = new Date()
  public updatedAt: Date = new Date()
  public manufacturer: string
  public model: string

  public constructor(data: any) {
    this.id = data.id

    if (data.user) {
      this.user = new User(data.user)
    }

    this.transactionId = data.transactionId

    this.financialTransactionId = data.financialTransactionId

    this.datetimeAvailable = new Date(data.datetimeAvailable)

    this.datetimeOccurrence = new Date(data.datetimeOccurrence)

    this.dueDate = new Date(data.dueDate)
    this.isOverdue = data.isOverdue === true

    this.licensePlate = data.licensePlate

    this.tagIdentifier = data.tagIdentifier

    this.vehicleClassRated = data.vehicleClassRated

    this.rateAmount = data.rateAmount
    this.discount = data.discount || 0
    this.discountDuf = data.discountDuf || 0
    this.discountDbt = data.discountDbt || 0
    this.fine = data.fine || 0
    this.interest = data.interest || 0
    this.fee = data.fee || 0

    this.plazaCode = data.plazaCode

    this.plazaDescription = data.plazaDescription

    this.laneCode = data.laneCode

    this.status = data.status

    this.isReSynchorization = data.isReSynchorization

    this.createdAt = new Date(data.createdAt)

    this.updatedAt = new Date(data.updatedAt)

    this.manufacturer = data.manufacturer

    this.model = data.model
  }
}
