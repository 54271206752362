import { CheckoutInput } from "../entities/csg/CheckoutInput"

const storageKey = "@checkout"
export default class CheckoutStorage {
  static get = (): CheckoutInput|null => {
    let str = localStorage.getItem(storageKey)
    if (str === null) return null    
    const data = JSON.parse(str)
    return new CheckoutInput(data)
  }

  static set = (data: CheckoutInput): void => {
    localStorage.setItem(storageKey, JSON.stringify(data))
  }
}