export class BalanceReturn {
  public id: number
  public amount: number
  public tax: number
  public type: string
  public key: string
  public status: string

  public constructor(data: any) {
    this.id = data.id || 0
    this.amount = data.amount || 0
    this.tax = data.tax || 0
    this.type = data.type || ''
    this.key = data.key || ''
    this.status = data.status  || ''
  }
}