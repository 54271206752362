import { Role, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class RoleRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<Role>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/role?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<Role>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<Role> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/role/${id}`, {}, headers)

    return new Role(response)
  }

  public static async getByCode(code: string): Promise<Role> {
    const response = await client.DoRequest('GET', `/role/byCode/${code}`)

    return new Role(response)
  }

  public static async create(role: Role, token: string): Promise<Role> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('POST', '/role', role, headers)

    return new Role(response)
  }

  public static async update(role: Role, token: string): Promise<Role> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('PUT', `/role/${role.id}`, role, headers)

    return new Role(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` };

    const response = await client.DoRequest('DELETE', `/role/${id}`, {}, headers)

    return response === true
  }
}