import { SearchResult } from "../../entities"
import { Charge } from "../../entities/csg"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class ChargeRepository {
  public static async searchByPlate(licensePlate: string, token: string): Promise<SearchResult<Charge>> {
    const headers = { Authorization: `Bearer ${token}` }

    const url = `/charge?licensePlate=${licensePlate}&page=1&limit=9999`

    const response = await client.DoRequest("GET", url, {}, headers)

    const data: Charge[] = []
    for (const row of response.data) {
      data.push(new Charge(row))
    }
    const result = new SearchResult<Charge>(
      data,
      response.info
    )
    
    return result
  }

  public static async searchByPlates(licensePlates: string[], status: string|null, token: string): Promise<SearchResult<Charge>> {
    const headers = { Authorization: `Bearer ${token}` }

    let url = `/charge?licensePlates=${licensePlates.join(',')}&page=1&limit=9999`
    if (status) {
      url += '&status=' + status
    }

    const response = await client.DoRequest("GET", url, {}, headers)

    const data: Charge[] = []
    for (const row of response.data) {
      data.push(new Charge(row))
    }
    const result = new SearchResult<Charge>(
      data,
      response.info
    )
    
    return result
  }
}