import React, { useState } from "react"
import { Flex, Text, VStack, HStack, Image, Divider } from "@chakra-ui/react"
import ActivityHistoryCard from "../../../components/activityHistoryCard/ActivityHistoryCard"
import Layout from "../../../components/layout/Layout/Layout"
import PaymentCard from "../../../components/paymentCard/PaymentCard"

export default function ActivityDetails() {
  const initialActivityHistoryItem = [
    {
      id: 1,
      icon: "/images/card-icon/HourglassHigh.svg",
      title: "RTX 3080",
      status: "Pago",
      statusCode: "paid",
      value: "15,00",
      date: "20/08/23 10:50",
      dueDate: "23/09/23",
      subTitle: "BRS-470, KM 228",
    },
  ]

  const [activityHistoryItem] = useState(initialActivityHistoryItem)

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Detalhes de atividade",
        backTo: "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Text fontSize="1rem" fontWeight="700" color="#2D3748">
          Detalhamento da cobrança
        </Text>
        <Divider orientation="horizontal" my={2} />
        <Flex w="full" mb={2}>
          {activityHistoryItem.map((activityHistoryItem) => (
            <ActivityHistoryCard
              id={activityHistoryItem.id}
              icon={activityHistoryItem.icon}
              title={activityHistoryItem.title}
              status={activityHistoryItem.status}
              statusCode={activityHistoryItem.statusCode}
              value={activityHistoryItem.value}
              date={activityHistoryItem.date}
              dueDate={activityHistoryItem.dueDate}
              subTitle={activityHistoryItem.subTitle}
            />
          ))}
        </Flex>
        <Flex
          mt={2}
          mb={2}
          bg="#E2E8F080"
          p="0.75rem"
          direction="column"
          borderRadius="0.8rem"
        >
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Total da cobrança
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              R$ 15,00
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Descontos
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              R$ 0,00
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="600">
              Acréscimos
            </Text>
            <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
              R$ 0,00
            </Text>
          </HStack>
          <Divider orientation="horizontal" bg="lightgray" my={2} />
          <HStack justifyContent="space-between">
            <Text color="#2D3748" fontSize="0.875rem" fontWeight="700">
              Total pago
            </Text>
            <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
              R$ 15,00
            </Text>
          </HStack>
        </Flex>
        <VStack w="100%">
          <Text
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Dados do veículo
          </Text>
          <VStack w="100%">
            <PaymentCard
              id={0}
              licensePlate="GTX 1650"
              manufacturer="Volkswagen Gol"
              model=""
              status="#E7ECFF"
              statusBold="#3531C6"
            />
          </VStack>
        </VStack>
        <HStack
          mt="1rem"
          bg="#F7F8F9"
          p="0.625rem 2rem 0.625rem 0.75rem"
          borderRadius="0.5rem"
          display="flex"
          gap="0.625rem"
          alignItems="center"
        >
          <Image src="/images/card-icon/Info.svg" alt="Ícone de informação" />
          <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
            Após pagamento você recebe acesso ao DFE (Documento Fiscal Equivalente)
            por e-mail - https://cis.csg.com.br.
          </Text>
        </HStack>
      </Flex>
    </Layout>
  )
}
