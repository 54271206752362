import { Text } from "@chakra-ui/react";

export default function Terms() {
  return (
    <div>
      <Text>TERMO E CONDIÇÕES DE USO</Text>

      <Text>O Termo e Condições de Uso (“Termo”) relaciona as principais regras que devem ser observadas por todos que utilizarem o aplicativo da CSG (Caminhos da Serra Gaúcha S.A.) ou utilizarem suas funcionalidades.</Text>
      <Text>Como condição para acesso e uso das funcionalidades exclusivas do aplicativo da CSG, o usuário declara que fez a leitura completa e atenta deste Termo, estando plenamente ciente, conferindo, assim, sua livre e expressa concordância com as disposições aqui estipuladas.</Text>
      <Text>Caso discorde de alguma das previsões deste Termo, solicitamos que não utilize os serviços do aplicativo.</Text>
      <Text></Text>
      <Text>1. LICENÇA LIMITADA</Text>
      <Text>Ao baixar, instalar e executar o aplicativo em seu dispositivo o usuário recebe uma licença limitada, não transferível, não exclusiva, livre de royalties e revogável. O usuário reconhece e concorda que a CSG concede ao usuário uma licença exclusiva para uso e desta forma não lhe transfere os direitos sobre o produto.</Text>
      <Text>O aplicativo deverá ser utilizado pelo usuário. A venda, transferência, modificação, engenharia reversa ou distribuição, bem como cópia de textos, imagens ou quaisquer partes nele contidas é expressamente proibida.</Text>
      <Text></Text>
      <Text>2. FUNÇÕES DO APLICATIVO</Text>
      <Text>Finalidade: o aplicativo é uma plataforma para que os usuários da CSG possam consultar e realizar o pagamento da tarifa de passagem.</Text>
      <Text>Quem pode usar: o acesso e utilização é livre para todos os usuários dos sistemas operacionais iOS e Android, desde que as versões deste sistema estejam passíveis de atualização pelos desenvolvedores do sistema operacional, contudo algumas funções podem exigir que seja praticado por maiores de 18 (dezoito) anos, conforme legislação vigente.</Text>
      <Text>Cadastro: o aplicativo possui área de conteúdo aberto e restrito. Para acesso ao conteúdo restrito será necessário que o usuário realize um cadastro fornecendo informações pessoais para criação de login e senha.</Text>
      <Text>O usuário deve ficar atento se as informações fornecidas estão corretas, pois é o responsável pela veracidade das mesmas.</Text>
      <Text>A CSG poderá recusar, suspender ou cancelar o acesso ou cadastro do usuário sempre que suspeitar que as informações são falsas, incompletas, desatualizadas ou imprecisas ou ainda nos casos indicados em leis e regulamentos aplicáveis.</Text>
      <Text>A realização do cadastro é necessária para acesso às funcionalidades mais avançadas do aplicativo. Sem a realização do cadastro, o usuário do aplicativo terá seu acesso limitado a consulta de débitos e pagamento.</Text>
      <Text>Notificações: ao fornecer seus dados no aplicativo o usuário concorda em receber comunicações por e-mail e SMS, a partir dos dados de contato informados no cadastro, bem como notificações push, contendo conteúdo publicitário da CSG. Através do próprio aplicativo o usuário terá a opção de não receber tais notificações.</Text>
      <Text>O usuário compreende e concorda que a CSG enviará, também, comunicações de serviço e transacionais como parte integrante do funcionamento do aplicativo, para usuários cadastrados. Estas incluem, mas não se limitam a, situações como: cadastro de usuário e veículo, alteração de senha, abertura de ticket, recarga e resgate de saldo, passagens pelos pórticos, pagamentos de tarifa, entre outros. Essas comunicações de serviço e transacionais são essenciais para o uso adequado do aplicativo e serão enviadas por meio de SMS, e-mail e/ou notificações push, de acordo com as informações de contato fornecidas durante o processo de cadastro. É responsabilidade do usuário manter suas informações de contato atualizadas para garantir o recebimento de todas as comunicações importantes da CSG.</Text>
      <Text>Dúvidas: o usuário poderá interagir com a CSG por meio do envio de mensagem à Central de Atendimento de Atendimento ao Cliente, disponibilizado no aplicativo, ou pelo e-mail cac@csg.com.br, solicitando acesso, resolvendo problemas, retirando dúvidas ou realizando sugestões e reclamações.</Text>
      <Text>Atualizações do Termo: a CSG poderá alterar este Termo e as condições de acesso do aplicativo a qualquer momento e a seu critério sem a necessidade de comunicação ou aviso prévio aos usuários ou terceiros. O usuário poderá sempre verificá-lo por meio do acesso ao aplicativo. </Text>
      <Text>Havendo alterações ou atualizações do Termo ou dos acessos a CSG notificará o usuário pelas ferramentas disponíveis no aplicativo ou pelos meios de contato fornecidos pelo usuário.</Text>
      <Text>Layout do Aplicativo: o aplicativo e suas funcionalidades poderão passar por constantes aprimoramentos e atualizações. A CSG se compromete a preservar a funcionalidade do aplicativo e utilizar o layout adequado respeitando a usabilidade e navegabilidade de forma clara, completa, precisa e suficiente para que ocorra uma correta percepção do que está sendo praticado pelo usuário.</Text>
      <Text></Text>
      <Text>3. PRESERVAÇÃO DO APLICATIVO</Text>
      <Text>Compromisso: o usuário se compromete a não acessar áreas de programação do aplicativo, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes, bem como não realizar ou permitir engenharia reversa, traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir, ou de outra maneira, dispor inapropriadamente das funções do aplicativo.</Text>
      <Text>Não mineração: é vedado a utilização de softwares de mineração de dados do aplicativo de qualquer tipo ou espécie, ou qualquer outro que atue de modo similar.</Text>
      <Text>Responsabilidade: a CSG utilizará de tecnologias modernas e aplicará regras de segurança, porém, na ocorrência de danos à CSG ou a terceiros, o responsável se compromete a arcar com a indenização e demais consequências do indivíduo lesado, assumindo o polo passivo de eventual ação judicial ou procedimento administrativo e requerendo a exclusão da CSG, devendo arcar com todas as despesas e custas processuais necessárias.</Text>
      <Text></Text>
      <Text>4. PROPRIEDADE DIGITAL E INTELECTUAL</Text>
      <Text>Propriedade: todas as informações, notícias, imagens, ilustrações, ícones, tecnologias e demais conteúdos audiovisuais ou sonoros, incluindo o software do aplicativo, disponibilizado em qualquer idioma ou forma, são de exclusiva propriedade da CSG ou licenciado por terceiros à CSG. A utilização do aplicativo não representa qualquer presunção de transferência ou licença dessa propriedade, não devendo, o usuário, compartilhar ou comercializar no todo ou em parte, o conteúdo aqui definido, por qualquer meio, podendo ser responsabilizado civil e penalmente pela CSG ou qualquer terceiro titular do conteúdo. </Text>
      <Text>Se o aplicativo for utilizado para fraudar ou violar este Termo ou qualquer legislação vigente, a CSG poderá recusar, não aprovar, cancelar ou limitar quaisquer solicitações do usuário.</Text>
      <Text>A remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade do aplicativo em decorrência de alguma solicitação ou reclamação deverá ser entendida como demonstração de boa-fé e tentativa de resolução amigável de conflito, jamais como reconhecimento de culpa ou de qualquer infração pela CSG a direito de terceiro.</Text>
      <Text>Dados: O usuário é e continuará sendo o titular e proprietário dos dados, informações, textos, imagens, mensagem ou qualquer outro material fornecidos ou compartilhados no aplicativo, sendo o responsável por eles, respeitando a propriedade intelectual de terceiros ou quaisquer outros direitos.</Text>
      <Text>Tratamento dos Dados: a base de dados formada por meio da coleta de dados é de propriedade da CSG e está sob responsabilidade da empresa, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos na Política de Privacidade e Proteção de Dados, disponível aos usuários para consulta no endereço eletrônico freeflow.csg.com.br.</Text>
      <Text>A coleta de dados pessoais varia de acordo com a integração do titular dos dados com a CSG. </Text>
      <Text>A realização de cadastro do usuário no aplicativo da CSG, com o fornecimento dos dados necessário, importa em consentimento explícito da Política de Privacidade e Proteção de Dados da CSG, bem como de seu respectivo compartilhamento. O não fornecimento ou revogação do fornecimento dos dados necessários impede a manutenção do cadastro. Para estes usuários, o uso do aplicativo estará limitado aos recursos disponíveis aos usuários não cadastrados.</Text>
      <Text>Armazenamento dos Dados: os dados pessoais coletados e os registros de atividade são armazenados em ambiente seguro e controlados enquanto perdurar a necessidade do tratamento, enquanto o cadastro do usuário permanecer ativo, ou, ao menos, pelo prazo de cinco anos. Realizado o cancelamento do cadastro do usuário, os dados pessoais coletados permanecerão armazenados em ambiente seguro pelo mesmo prazo de até cinco anos.</Text>
      <Text>Registro: a CSG poderá registrar todas as atividades efetuadas pelo usuário no aplicativo, incluindo identificação do dispositivo e da conexão utilizada.</Text>
      <Text>Finalidade da Coleta: os dados e registros coletados poderão ser utilizados para identificação e atendimento do usuário, cumprir as obrigações do presente Termo e da Política de Privacidade e Proteção de Dados da CSG, emissão de Documento Fiscal Equivalente (DFE), aperfeiçoar o aplicativo e cumprir ordem judicial e/ou de autoridade administrativa.</Text>
      <Text></Text>
      <Text>5. DEPÓSITO E RESGATE DE SALDO</Text>
      <Text>O usuário poderá realizar o depósito de saldo em sua conta cadastrada no aplicativo da CSG, para pagamento das tarifas de pedágio. O saldo existente na conta será utilizado para abatimento automático do valor da tarifa, sempre que houver o registro de passagem de um dos veículos vinculados ao cadastro, em um dos pórticos de pedágio da CSG.</Text>
      <Text>A CSG não armazenará os dados de pagamento do usuário. Toda a transação será conduzida e protegida por um gateway de pagamento seguro, e a CSG não terá acesso direto às informações de pagamento do usuário.</Text>
      <Text>O usuário poderá solicitar o resgate de qualquer eventual saldo depositado em sua conta no aplicativo da CSG, mediante o desconto de uma taxa de administração, para fins de compensação dos custos da CSG relativos ao processamento da operação, composta por um percentual de 3,89% sobre o valor do resgate e uma taxa fixa de R$ 0,05. O usuário reconhece que a taxa de administração para o resgate do saldo é justa e necessária para cobrir os custos operacionais da CSG relacionados ao processamento do resgate do saldo e, ao solicitar o resgate do saldo, o usuário concorda expressamente com a dedução da taxa de administração do valor a ser resgatado.</Text>
      <Text>O resgate do saldo será realizado de acordo com as instruções fornecidas pelo usuário no momento da solicitação do resgate. Ao solicitar o resgate do saldo, o usuário concorda em fornecer quaisquer informações adicionais que a CSG possa exigir para processar o resgate. A CSG não será responsável por qualquer perda decorrente de falhas no fornecimento das informações corretas pelo usuário para o resgate do saldo.</Text>
      <Text>O prazo para o resgate do saldo será de 33 dias a partir da data da solicitação. Este prazo é necessário para o processamento do pedido e a realização das verificações de segurança pertinentes.</Text>
      <Text>Todas as solicitações de depósito e resgate de saldo estão sujeitas às leis e regulamentos aplicáveis, incluindo, mas não se limitando a, leis e regulamentos sobre lavagem de dinheiro e financiamento do terrorismo. A CSG se reserva o direito de recusar o resgate do saldo em casos de suspeita de fraude, uso indevido da conta ou violação deste Termo.</Text>
      <Text>A CSG se reserva o direito de alterar a taxa de administração e o prazo para o resgate do saldo a qualquer momento, a seu exclusivo critério. Quaisquer alterações entrarão em vigor imediatamente após a publicação das alterações, nos termos definidos na Cláusula 2 deste Termo.</Text>
      <Text></Text>
      <Text>6. DAS PENALIDADES POR ATRASO NO PAGAMENTO DA TARIFA DE PEDÁGIO</Text>
      <Text>O usuário se declara ciente de que precisará efetuar o pagamento da tarifa de pedágio devida, em razão da passagem nos pórticos de pedágio da CSG, dentro do prazo estipulado na legislação vigente no momento do registro de sua passagem.</Text>
      <Text>Na hipótese de não pagamento da tarifa de pedágio dentro do prazo legal, o usuário se declara ciente, também, que, além do encaminhamento para o Departamento Autônomo de Estradas de Rodagem do Rio Grande do Sul (DAER/RS) para a aplicação da penalidade prevista no art. 209-A do Código de Trânsito Brasileiro (CTB), referente à evasão de pedágio, incidirão sobre o valor devido:</Text>
      <Text>a) Multa moratória de 2% (dois por cento), uma única vez, sobre o valor da tarifa de pedágio não paga;</Text>
      <Text>b) Juros moratórios de 1% (um por cento) ao mês, calculados pro rata temporis, ou seja, proporcionalmente ao número de dias de atraso, até o efetivo pagamento.</Text>
      <Text>Ao concordar com os termos e condições de uso, o usuário expressamente reconhece e consente com a aplicação das penalidades mencionadas, incluindo a multa moratória e os juros de mora, e compromete-se a realizar o pagamento dos valores apurados conjuntamente com o valor da tarifa, estando ciente, também, de que não será possível realizar o pagamento parcial de uma tarifa, assim como não será possível realizar o pagamento da tarifa sem a incidência de eventuais penalidades incididas em razão do atraso.</Text>
      <Text>O uso contínuo do aplicativo da CSG pelo usuário constitui aceitação plena e incondicional dos termos estabelecidos nesta cláusula.</Text>
      <Text></Text>
      <Text>7. DIREITOS DO USUÁRIO</Text>
      <Text>O usuário poderá solicitar ao Encarregado de Dados da CSG, através de Formulário de Requisição de Dados, a confirmação da existência de tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio de nossos Canais de Comunicação.</Text>
      <Text>São direitos dos titulares: a confirmação da existência de tratamento de dados pessoais; o acesso aos dados pessoais, nos termos da legislação aplicável; a correção de dados pessoais incompletos, inexatos ou desatualizados; a portabilidade dos dados pessoais; a exclusão dos seus dados pessoais, quando este forem tratados com base no consentimento do titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável; solicitar informações sobre o uso compartilhado de dados pessoais; e revogar seu consentimento de uso de dados, quando aplicável.</Text>
      <Text>Eventuais dúvidas, sugestões, ou incidentes podem ser transmitidas através do Canal de comunicação com o Encarregado de Dados (DPO):  privacidade@csg.com.br.</Text>
      <Text>a) Dados do Encarregado: Guilherme Tessari, CPF nº 026.488.000-50. Contato: privacidade@csg.com.br. </Text>
      <Text>b) Dados do Controlador: CAMINHOS DA SERRA GAUCHA S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob nº 47.815.827/0001-17. Contato: privacidade@csg.com.br. </Text>
      <Text></Text>
      <Text>8. EMISSÃO DO DOCUMENTO FISCAL EQUIVALENTE</Text>
      <Text>Nos termos da Instrução Normativa RFB nº 1.731/2017, o usuário poderá realizar a emissão de seu Documento Fiscal Equivalente (DFE) em até 07 (sete) dias após a passagem pelo pedágio através do endereço eletrônico cis.csg.com.br. A passagem pelo pedágio poderá demorar até 12 (doze) horas para estar disponível para emissão.</Text>
      <Text>A emissão do DFE será realizada com o fornecimento de informação, pelo usuário, da placa e data de passagem pelo pórtico de pedágio, a partir do qual poder-se-á incluir informações como identificação do proprietário do veículo para emissão do documento fiscal.</Text>
      <Text>Em caso de dúvidas, o usuário deverá entrar em contato na Central de Atendimento ao Usuário, através do e-mail cac@csg.com.br.</Text>
      <Text></Text>
      <Text>9. LIMITAÇÕES DA RESPONSABILIDADE</Text>
      <Text>Indisponibilidade do Aplicativo: no caso de indisponibilidade temporária decorrente de manutenção necessária ou por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à internet, ataques cibernéticos, ou quaisquer fatos de terceiro que fogem à responsabilidade da CSG, a empresa se compromete a restabelecer o acesso ao aplicativo o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiro dos quais dependa para ficar online.</Text>
      <Text>Caso haja indisponibilidade do aplicativo que coincida com o prazo legal para pagamento de uma tarifa de pedágio, e essa indisponibilidade resulte em atraso no pagamento e consequente aplicação de multas ou acréscimos, o usuário deve entrar em contato com a Central de Atendimento ao Cliente (CAC) através do email cac@csg.com.br.</Text>
      <Text>O usuário fica ciente que não poderá pedir indenização ou reparação de danos no caso do aplicativo permanecer fora do ar, independente do motivo. No entanto, é responsabilidade do usuário entrar em contato com a CAC caso enfrente problemas decorrentes de indisponibilidade do aplicativo.</Text>
      <Text>Manutenção: todas as manutenções que gerem a indisponibilidade do aplicativo por longos períodos serão informados por meio dos canais oficiais de comunicação da CSG.</Text>
      <Text>Isenção de Responsabilidade: sem prejuízo de outras condições deste Termo a CSG não se responsabiliza por:</Text>
      <Text>I. Qualquer problema, bugs, glitches ou funcionamento indevido que ocorrer no seu dispositivo e equipamento;</Text>
      <Text>II. Por qualquer dano direto ou indireto ocasionado por terceiros como falhas no sistema, no servidor ou na conexão à internet, inclusive por ações de softwares que possam, de algum modo, danificar seus ativos físicos ou lógicos em decorrência do acesso, utilização ou navegação no aplicativo, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos no aplicativo.</Text>
      <Text>III. Pela navegação do usuário em quaisquer links externos disponibilizados no aplicativo, sendo seus os deveres de leitura e verificação da confiabilidade das informações, dos Termos e Condições de Uso e da Política de Privacidade do recurso acessado e agir conforme o determinado.</Text>
      <Text></Text>
      <Text>10. CANCELAMENTO DA CONTA E TÉRMINO DO USO</Text>
      <Text>O usuário pode optar por encerrar sua conta no aplicativo a qualquer momento, por qualquer motivo, seguindo as instruções disponíveis na seção de configurações do aplicativo. O cancelamento do cadastro impedirá o usuário de acessar e utilizar as funções mais avançadas do aplicativo.</Text>
      <Text>Se a CSG detectar qualquer uso indevido do aplicativo ou violação deste Termo de Uso, a empresa se reserva o direito de suspender ou encerrar a conta do usuário a seu critério exclusivo e sem aviso prévio. Em caso de término por parte da CSG, o usuário será notificado pelos meios de contato (e-mail, SMS) associados à sua conta.</Text>
      <Text>No caso de encerramento ou suspensão da conta, o usuário não terá direito a qualquer tipo de compensação ou indenização. No entanto, as obrigações e responsabilidades do usuário com relação ao uso do aplicativo antes do término continuarão em vigor, mesmo após o encerramento da conta.</Text>
      <Text></Text>
      <Text>11. DISPOSIÇÕES GERAIS</Text>
      <Text>Ao utilizar o aplicativo e suas funcionalidades o usuário aceita as disposições do Termo que se encontra vigente na data do acesso.</Text>
      <Text>A tolerância do eventual descumprimento de quaisquer condições destes Termos não constitui renúncia nem impedirá a CSG de exigir estas condições a qualquer tempo.</Text>
      <Text>O usuário reconhece que toda comunicação realizada por e-mail (informado no cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira ao aplicativo.</Text>
      <Text>A data e hora do aplicativo tem como base a data e horário oficial de Brasília (UTC/GMT -03:00).</Text>
      <Text>Este Termo será interpretado segundo a legislação brasileira, no idioma português, com foro de eleição na Comarca de Farroupilha/RS para dirimir qualquer controvérsia, salvo se a legislação aplicável ressalvar outra competência pessoal, territorial ou funcional.</Text>
    </div>
  )
}