export class Role {
  public id = 0
  public name = ''
  public code = ''

  constructor (data: any = {}) {
    this.id = data.id ? parseInt(data.id) : 0
    this.name = data.name
    this.code = data.code
  }
  
}