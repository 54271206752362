import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import InputMask from "react-input-mask"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  Select,
} from "@chakra-ui/react"
import Layout from "../../../../components/layout/Layout/Layout"
import { States, User } from "../../../../entities"
import { AuthService, UserService } from "../../../../services"
import UserStorage from "../../../../util/userStorage"
import Loader from "../../../../components/Loader/Loader"
import { PostcodeService } from "../../../../services/csg"
import { ValidationInput } from "../../../../util"

export default function UserFormEdit() {
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState<User>(new User())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      loadUser()
    })

    // eslint-disable-next-line
  }, [])

  const loadUser = () => {
    setIsLoading(true)
    AuthService.getUserByToken()
      .then((user) => setForm(user))
      .finally(() => setIsLoading(false))
  }

  const searchPostcode = (postcode: string) => {
    if (postcode.length !== 9) return

    setIsLoading(true)
    PostcodeService.searchPostcode(postcode)
      .then((data) => {
        console.log("data", data)
        if (data?.erro === true) {
          toast({
            title: "CEP inválido",
            status: "error",
          })
          return
        }

        setForm({
          ...form,
          postcode: postcode,
          address1: data.logradouro,
          address2: data.bairro,
          city: data.localidade,
          state: data.uf,
          addressNumber: "",
          addressComplement: "",
        })
      })
      .finally(() => setIsLoading(false))
  }

  const save = async () => {
    const user = new User(form)
    user.roleId = user.role.id

    if (user.firstname.length <= 2) {
      toast({
        title: "Digite o primeiro nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.lastname.length <= 2) {
      toast({
        title: "Digite o sobrenome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.type === "PF") {
      if (user.document.length < 14) {
        toast({
          title: "Digite o CPF.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCPF(user.document)) {
          toast({
            title: "O CPF informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }

      if (user.birthDate === null) {
        toast({
          title: "Digite a data de nascimento.",
          status: "error",
          duration: 2000,
        })
        return
      }
    } else {
      if (user.document.length < 18) {
        toast({
          title: "Digite o CNPJ.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCNPJ(user.document)) {
          toast({
            title: "O CNPJ informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }

      if (user.companyName.length < 3) {
        toast({
          title: "Digite a Razão Social.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.businessName.length < 3) {
        toast({
          title: "Digite o Nome Fantasia.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.post.length < 3) {
        toast({
          title: "Digite o Cargo.",
          status: "error",
          duration: 2000,
        })
        return
      }
    }

    if (user.postcode.length < 9) {
      toast({
        title: "Digite o CEP.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address1.length < 3) {
      toast({
        title: "Digite o Endereço.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.city.length < 3) {
      toast({
        title: "Digite a Cidade.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.state.length !== 2) {
      toast({
        title: "Escolha o Estado.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address2.length < 3) {
      toast({
        title: "Digite o Bairro.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.addressNumber.length < 1) {
      toast({
        title: "Digite o Nº.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    setIsLoading(true)
    UserService.update(user)
      .then((result) => navigate("/perfil"))
      .catch((error) => {
        console.log("error", error)
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Editar Perfil",
        backTo: "/perfil",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        mt="1rem"
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1rem" fontWeight="700">
            Editar perfil
          </Text>
        </Flex>
        <FormControl isRequired>
          <FormLabel>Nome</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.firstname}
            onChange={(event) =>
              setForm({ ...form, firstname: event.target.value })
            }
            placeholder="Primeiro nome"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Sobrenome</FormLabel>
          <Input
            size="lg"
            type="text"
            value={form.lastname}
            onChange={(event) =>
              setForm({ ...form, lastname: event.target.value })
            }
            placeholder="Sobrenome"
          />
        </FormControl>
        <VStack spacing="1rem" mb="2rem">
          {form.type === "PJ" && (
            <FormLabel
              fontSize="1rem"
              fontWeight="700"
              color="#2D3748"
              alignSelf="flex-start"
            >
              Dados da empresa
            </FormLabel>
          )}
          <FormControl isRequired>
            <FormLabel>{form.type === "PF" ? "CPF" : "CNPJ"}</FormLabel>
            <Input
              as={InputMask}
              mask="999.999.999-99"
              maskChar={null}
              size="lg"
              type="text"
              value={form.document}
              onChange={(event) =>
                setForm({ ...form, document: event.target.value })
              }
              placeholder="000.000.000-00"
            />
          </FormControl>
          {form.type === "PF" && (
            <FormControl isRequired>
              <FormLabel>Data de nascimento</FormLabel>
              <Input
                size="lg"
                type="date"
                value={
                  form.birthDate
                    ? form.birthDate.toISOString().split("T")[0]
                    : ""
                }
                onChange={(event) =>
                  setForm({
                    ...form,
                    birthDate: new Date(event.target.value),
                  })
                }
                placeholder="01/01/2000"
              />
            </FormControl>
          )}
          {form.type === "PJ" && (
            <>
              <FormControl isRequired>
                <FormLabel>Razão social</FormLabel>
                <Input
                  size="lg"
                  type="text"
                  value={form.companyName}
                  onChange={(event) =>
                    setForm({ ...form, companyName: event.target.value })
                  }
                  placeholder="Razão social"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Nome fantasia</FormLabel>
                <Input
                  size="lg"
                  type="text"
                  value={form.businessName}
                  onChange={(event) =>
                    setForm({ ...form, businessName: event.target.value })
                  }
                  placeholder="Nome fantasia"
                />
              </FormControl>
            </>
          )}
          <FormControl isRequired>
            <FormLabel>CEP</FormLabel>
            <Input
              as={InputMask}
              mask="99999-999"
              maskChar={null}
              size="lg"
              type="text"
              value={form.postcode}
              onChange={(event) => {
                const postcode = event.target.value
                setForm({ ...form, postcode: postcode })
                searchPostcode(postcode)
              }}
              placeholder="00000-000"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Endereço</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.address1}
              onChange={(event) =>
                setForm({ ...form, address1: event.target.value })
              }
              placeholder="Seu endereço"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Cidade</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.city}
              onChange={(event) =>
                setForm({ ...form, city: event.target.value })
              }
              placeholder="Cidade"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Estado</FormLabel>
            <Select
              size="lg"
              placeholder="Estado"
              value={form.state}
              onChange={(event) =>
                setForm({ ...form, state: event.target.value })
              }
            >
              {States.map((item: any) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Bairro</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.address2}
              onChange={(event) =>
                setForm({ ...form, address2: event.target.value })
              }
              placeholder="Bairro"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Número</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.addressNumber}
              onChange={(event) =>
                setForm({ ...form, addressNumber: event.target.value })
              }
              placeholder="Nº"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Complemento</FormLabel>
            <Input
              size="lg"
              type="text"
              value={form.addressComplement}
              onChange={(event) =>
                setForm({ ...form, addressComplement: event.target.value })
              }
              placeholder="Ex.: apto 913"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Telefone</FormLabel>
            <Input
              as={InputMask}
              mask="(99) 99999-9999"
              maskChar={null}
              size="lg"
              type="text"
              value={form.phone}
              onChange={(event) =>
                setForm({ ...form, phone: event.target.value })
              }
              placeholder="(00) 00000-0000"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>E-mail</FormLabel>
            <Input
              size="lg"
              type="email"
              value={form.email}
              onChange={(event) =>
                setForm({ ...form, email: event.target.value })
              }
              placeholder="email@provedor.com"
            />
          </FormControl>
          {form.type === "PJ" && (
            <FormControl isRequired>
              <FormLabel>Cargo</FormLabel>
              <Input
                size="lg"
                type="text"
                value={form.post}
                onChange={(event) =>
                  setForm({ ...form, post: event.target.value })
                }
                placeholder="Ex.: Diretor Financeiro"
              />
            </FormControl>
          )}
        </VStack>
        <Flex direction="column" mt="1rem">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            variant="solid"
            bg="#00335B"
            color="#fff"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
            onClick={save}
          >
            Salvar alterações
          </Button>
        </Flex>
      </Flex>
    </Layout>
  )
}
