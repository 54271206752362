import { Vehicle } from "./Vehicle"
import { VehicleTransferDocument } from "./VehicleTransferDocument"

export class VehicleTransfer {
  public id: number
  public vehicle: Vehicle
  public reason: string
  public explanation: string
  public status: string
  public createdAt: Date|null
  public updatedAt: Date|null

  public documents: VehicleTransferDocument[] = []

  public constructor(data: any ={}) {
    this.id = data.id || 0
    this.vehicle = new Vehicle(data.vehicle)
    this.reason = data.reason || ''
    this.explanation = data.explanation || ''
    this.status = data.decision || 'open'
    this.createdAt = data.createdAt ? new Date(data.createdAt) : null
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : null

    if (data.documents) {
      for (const document of data.documents) {
        this.documents.push(new VehicleTransferDocument(document))
      }
    }
  }
}