import React from "react"
import { Flex } from "@chakra-ui/react"
import FooterMenu from "../../footerMenu/FooterMenu"
import Header, { HeaderProps } from "../header/Header"

interface LayoutProps {
  internal?: boolean
  header?: HeaderProps
  children: any
  valign?: string
  menuSelected?: string
}

export default function Layout(props: LayoutProps) {
  const valign = props.valign || "center"
  const justifyContentMain = valign === "center" ? "center" : "flex-start"
  const justifyContentContainer =
    valign === "center" ? "center" : "space-between"
  const minHContainer = valign === "center" ? "1vh" : "100vh"

  const minHChildren =
    valign === "center" ? "1vh" : props.header ? "85vh" : "100vh"

  return (
    <>
      <Flex
        w="100%"
        minH="100vh"
        direction="column"
        justifyContent={justifyContentMain}
      >
        <Flex
          w="100%"
          p="1rem"
          direction="column"
          alignItems="center"
          justifyContent={justifyContentContainer}
          minH={minHContainer}
        >
          {props.header && <Header {...props.header} />}
          <Flex
            w="100%"
            maxW="515px"
            direction="column"
            minH={minHChildren}
            justifyContent="space-between"
          >
            {props.children}
          </Flex>
        </Flex>
      </Flex>
      {props.internal && <FooterMenu menuSelected={props.menuSelected} />}
    </>
  )
}
