import { Text } from "@chakra-ui/react";

export default function Politics() {
  return (
    <div>
      <Text>Política de Privacidade e Proteção de dados</Text>
      <Text></Text>
      <Text>Farroupilha/RS, 6 de novembro de 2023.</Text>
      <Text></Text>
      <Text>CAMINHOS DA SERRA GAÚCHA S/A (“CSG”), pessoa jurídica de direito privado,
sociedade anônima de capital fechado, inscrita no CNPJ sob o n.º 47.815.827/0001-17,
com sede na Rua José Dalla Riva, n° 441, bairro Centro, município de Farroupilha/RS,
utiliza-se desta Política para demonstrar seu compromisso com a privacidade e proteção
dos dados pessoais coletados em nosso site, bem como aquelas que nos são
compartilhadas pelos usuários.</Text>
      <Text></Text>
      <Text>1. DA POLÍTICA DE PROTEÇÃO DE DADOS</Text>
      <Text>A presente Política contém informações sobre o tratamento de dados pessoais
realizados pela Concessionária Caminhos da Serra Gaúcha, doravante denominada CSG
ou controladora.</Text>
      <Text>Ela destina-se a auxiliar todos que de alguma forma terão seus dados coletados e
tratados pela CSG, de forma a transparecer a finalidade do tratamento dos dados pessoal
bem como a maneira que poderá o titular atualizar, gerenciar ou excluir suas informações.</Text>
      <Text>Eventuais dúvidas, sugestões, ou incidentes podem ser transmitidas através do
Canal de comunicação com o Encarregado de Dados (DPO): por meio do e-mail:
privacidade@csg.com.br.</Text>
      <Text>Como condição para acesso e uso das funcionalidades exclusivas do website e
aplicativos da CSG, o usuário declara que fez a leitura completa e atenta desta Política,
estando plenamente ciente, conferindo, assim, sua livre e expressa concordância com os
termos aqui estipulados, incluindo a coleta dos dados aqui mencionados, bem como sua
utilização para os fins abaixo especificados.</Text>
      <Text></Text>
      <Text>2. DADOS COLETADOS</Text>
      <Text>A base de dados formada por meio da coleta de dados é de nossa propriedade e está sob nossa responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios descritos nesta Política.</Text>
      <Text>A coleta de dados pessoais varia de acordo com a integração do titular dos dados com a CSG.</Text>
      <Text>Dentre as categorias de dados pessoais que podem ser objeto de tratamento relacionadas, citam-se as seguintes:</Text>
      <Text>2.1 Dados pessoais fornecidos pelo titular para cadastro</Text>
      <Text>Todos os dados são fornecidos de forma livre e de espontânea vontade, não sendo o usuário de nossos serviços obrigado a fornecer qualquer dado pessoal para acessar e navegar nas páginas e aplicativos da CSG. </Text>
      <Text>Somente é necessária a coleta de dados básicos quando o usuário desejar realizar o cadastro, sendo todas as informações colhidas de maneira justa e não invasiva, com o consentimento voluntário do usuário por meio da seleção da caixa de leitura e aprovação desta política.</Text>
      <Text>A realização de cadastro e uso das funcionalidades mais avançadas do aplicativo e site estarão disponíveis somente para usuários que consentirem com o fornecimento dos dados ora indicados, em razão da necessidade dessas informações para operacionalização de tais funcionalidades. O não fornecimento ou revogação do fornecimento dos dados necessários impede a manutenção do cadastro. Para estes usuários, o uso do aplicativo e site estará limitado aos recursos disponíveis aos usuários não cadastrados.</Text>
      <Text>Nome completo;
Dados do veículo – Placa/Marca/Modelo;
Documento de identidade – CPF/CNPJ;
Endereço completo;
Número de telefone celular;
Endereço de e-mail.
</Text>
      <Text>Dados financeiros</Text>
      <Text>Agência, conta bancária e/ou chave PIX.</Text>
      <Text></Text>
      <Text>2.2 Dados pessoais coletados automaticamente</Text>
      <Text>Dados técnicos</Text>
      <Text>Informações sobre seu dispositivo (computador, notebook, celular etc.);
Identificadores eletrônicos; 
Cookies; 
Localização física (com base no endereço de IP ou geolocalização); 
Histórico de navegação;
Outras informações técnicas semelhantes podem ser coletadas quando o usuário acessar nosso aplicativo e site.
</Text>
      <Text></Text>
      <Text>Dados relacionados ao seu uso dos produtos ou serviços da CSG</Text>
      <Text>Histórico de seus pedidos e reclamações; 
Números identificadores únicos em conexão ao uso do serviço (TAG); 
Placas de veículo;
Imagem do veículo ou do usuário são coletadas para podermos proporcionar os produtos e serviços.
</Text>
      <Text></Text>
      <Text>2.3 Atualização e veracidade dos dados:</Text>
      <Text>O usuário é o responsável pela precisão, veracidade ou falta dela em relação aos dados coletados, uma vez que único fornecedor destes, devendo mantê-los atualizados.</Text>
      <Text>A CSG não é obrigada a processar ou tratar quaisquer dados fornecidos pelos usuários se houver razões para crer que tal processamento ou tratamento possa imputar a empresa qualquer infração de qualquer lei aplicável, ou se o usuário utilizar nossas plataformas para quaisquer fins ilegais, ilícitos ou contrários à moralidade.</Text>
      <Text></Text>
      <Text>3. FORMA DE COLETA DE DADOS</Text>
      <Text>Os dados serão coletados através da passagem dos veículos nos pórticos de free-flow, bem como através do cadastro no site ou no aplicativo, ou mesmo pela consulta anônima de débitos. Ou seja, os dados são coletados quando submetidos pelo usuário, ou quando este interage com o website e aplicativo da concessionária. A realização de cadastro do usuário no site ou plataforma da CSG, com o fornecimento dos dados necessário, importa em consentimento explícito de compartilhamento destes dados.</Text>
      <Text>Ainda, cookies podem ser coletados ao utilizar o Website e aplicativo da empresa, como dados de navegação e IP do dispositivo, utilizados para melhor funcionamento do site bem como medir o desempenho das funcionalidades do portal.</Text>
      <Text>Os cookies não são compartilhados com terceiros, além daqueles responsáveis por sua coleta e tratamento. Eles podem ser ajustados nas configurações de seu navegador para serem desabilitados. Ao desabilitá-los algumas funcionalidades do website poderão ser limitadas, por dependerem dos cookies para seu desempenho.</Text>
      <Text></Text>
      <Text>4. FINALIDADE DOS DADOS COLETADOS</Text>
      <Text>DADO COLETADO
FINALIDADE
Dados cadastrais
Identificação do usuário;
Prestação de serviço de atendimento;
Cumprimento das obrigações legais e regulatórias;
Emissão os Documentos Fiscais Equivalentes (DFE) relativos à prestação do serviço;
Cadastros de veículos isentos de cobrança;
Monitoramento de tráfego de veículos em geral;
Envio de mensagens e comunicações transacionais e de serviço.
Dados de identificação digital / Dados técnicos
Identificação e autenticação do usuário;
Prestação de serviços de atendimento;
Operar e aperfeiçoar as plataformas;
Registro de informações para envio aos órgãos reguladores e autoridades competentes;
Realização de pesquisas e marketing de relacionamento;
Melhora na segurança e no funcionamento do sistema;
Elaboração de estatísticas.
Dados financeiros
Legítimo interesse de executar o serviço prestado;
Cumprimento das obrigações legais e regulatórias;
Realização de reembolsos, quando aplicável.

</Text>
      <Text></Text>
      <Text>5. COMPARTILHAMENTO DE DADOS E INFORMAÇÕES</Text>
      <Text>Os dados coletados pela CSG são se uso exclusivo interno da empresa, sendo compartilhado apenas nas seguintes situações:</Text>
      <Text>Mediante ordem judicial ou solicitação formal por órgãos de fiscalização, autoridades públicas, poder concedente e agências reguladoras;
Quando houver imprescindível necessidade para atender a dispositivo legal;
Dados pessoais que constem em notas fiscais eletrônicas e respectivos XMLs;
Dados compartilhados com parceiros, operadores, fornecedores e prestadores de serviços que participam, direta ou indiretamente, da execução das atividades da CSG, tais como serviços ao usuário, processamento de recolhimento da tarifa de passagem, monitoramento da rodovia e de sistemas da empresa, verificação de informações cadastrais, pesquisas, guarda e processamento de dados, envio de e-mails, processamento e cumprimento de ordens e transações;
Dados transferidos para fins de execução de contratos bem como para a proteção dos interesses da CSG em qualquer tipo de conflito, incluindo em processos judiciais ou administrativos.
</Text>
      <Text>Os dados serão tratados internamente na empresa por funcionários habilitados e treinados para realizar o tratamento de dados, os quais assinam termo de sigilo e confidencialidade das informações.</Text>
      <Text>Os dados jamais serão vendidos ou alugados a terceiros ou mesmo compartilhados com pessoas não autorizadas.</Text>
      <Text></Text>
      <Text>Transferência internacional de dados</Text>
      <Text>Alguns dos terceiros envolvidos no tratamento dos dados podem ser localizados ou possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados.</Text>
      <Text></Text>
      <Text>6. PRAZO DE ARMAZENAMENTO DOS DADOS</Text>
      <Text>Os dados coletados e registrados pelos pórticos ficam armazenados em banco de dados próprio pelo prazo máximo de trinta dias.</Text>
      <Text>Os dados pessoais coletados e os registros de atividade são armazenados em ambiente seguro e controlados enquanto perdurar a necessidade do tratamento, enquanto o cadastro do usuário permanecer ativo, ou, ao menos, pelo prazo de cinco anos. Realizado o cancelamento do cadastro do usuário, os dados pessoais coletados permanecerão armazenados em ambiente seguro pelo mesmo prazo de até cinco anos.</Text>
      <Text>Para fins de auditoria, segurança, controle de fraudes, proteção ao crédito e preservação de direitos, a empresa poderá permanecer com o histórico de registro de dados por prazo maior nas hipóteses que a lei ou norma regulatória assim estabelecer ou para preservação de direitos.
Esta Política de Privacidade se aplica às circunstâncias aqui mencionadas durante todo o período em que a CSG armazenar os dados pessoais.
O término do tratamento e armazenamento dos dados pessoais ocorrerá quando:
A finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade;
O usuário solicitar a exclusão de seus dados;
Houver determinação legal neste sentido.
Findo o prazo de manutenção e a necessidade legal, os dados pessoais serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos. Entretanto, não será possível a alteração dos dados dos Documentos Fiscais Equivalentes – DFE para os quais tenha sido vinculado o CPF ou a placa do veículo correspondente.
Para mais informações sobre períodos de retenção de dados, o usuário poderá entrar em contato com o Encarregado de proteção de dados pessoais.
</Text>
      <Text></Text>
      <Text>7. POLÍTICA DE SEGURANÇA DOS DADOS </Text>
      <Text>Como política para resguardar os dados coletados bem como amenizar, sempre tentando aniquilar o risco de vazamento, a empresa toma as seguintes medidas:
Os dados coletados são acessados somente por profissionais autorizados e habilitados para realizar o tratamento, os quais acessarão somente os dados relevantes para o exercício da atividade;
A CSG adota termos de responsabilidade e confidencialidade a todos os funcionários quem possuem acesso aos dados pessoais coletados, bem como garante que os terceiros prestadores de serviço estejam atendendo às disposições da Lei Geral de Proteção de Dados;
Observa e respeita estritamente a boa-fé e os princípios balizadores da atividade de tratamento de dados, quais sejam: finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação, responsabilização e prestação de contas;
Ainda, como controladora de dados, a CSG é responsável por:
Registrar as atividades de tratamento;
Adotar medidas de segurança técnicas e administrativas;
Garantir a segurança da informação;
Atender aos direitos dos titulares;
Cooperar com a Autoridade Nacional de Proteção de Dados (ANPD);
Garantir o atendimento aos princípios e bases legais da LGPD.
A CSG preza pela privacidade e segurança dos dados de seus usuários, assim, os dados não serão compartilhados com pessoas não autorizadas.
Ainda, possui diretrizes de privacidade e segurança aos seus empregados e parceiros comerciais.
Nesse sentido, a CSG se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.
É de extrema importância, também, a proteção de dados pelo próprio usuário, contra acessos não autorizados, contas ou senhas, certificando-se sempre em tomar as devidas medias de segurança, como: deslogar ao encerrar a navegação em um computador, se certificar da origem de e-mails, ligações e mensagens solicitando dados, e de links para download.
Ainda, cabe ao usuário realizar a leitura atenta da Política de Privacidade do website da CSG e demais portais e plataformas anexos, sendo de sua escolha aceitá-las ou rejeitá-las.
A CSG não se responsabiliza pelo tratamento de dados de terceiros e nem mesmo pelo conteúdo de serviços de ambientes que não da empresa.
A CSG jamais entrará em contato via telefone, SMS, aplicativo de mensagem ou ligação solicitando informações de dados pessoais.
Todas as tecnologias adotadas pela CSG sempre respeitarão a legislação brasileira de proteção de dados vigente e os termos desta Política.
</Text>
      <Text></Text>
      <Text>8. DIREITOS DOS TITULARES DOS DADOS</Text>
      <Text>O usuário pode solicitar ao Encarregado de Dados da CSG, através de Formulário de Requisição de Dados, a confirmação da existência de tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio de nossos Canais de Comunicação.
São direitos dos titulares, garantidos pela Lei Geral de Proteção de Dados:


Confirmação da existência de tratamento de dados pessoais;
Acesso aos dados pessoais, nos termos da legislação aplicável;
Correção de dados pessoais incompletos, inexatos ou desatualizados;
Realizar a portabilidade dos dados pessoais;
Excluir seus dados pessoais, quando este forem tratados com base no consentimento do titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável;
Solicitar informações sobre o uso compartilhado de dados pessoais;
Revogar seu consentimento de uso de dados, quando aplicável.


Qualquer violação de dados pessoais ou possibilidade de violação deverá ser urgentemente informada ao Encarregado de Proteção de Dados pessoais, para que sejam adotadas as medidas de prevenção e correção imediatas, necessárias para preservação da segurança de dados e informações.
</Text>
      <Text></Text>
      <Text>9. LIMITAÇÃO, OPOSIÇÃO, NOTIFICAÇÃO DE VAZAMENTO E DESCARTE DE DADOS</Text>
      <Text>Pelos canais de comunicação poderá o usuário requerer:
A limitação do uso de seus dados pessoais;
Sua oposição e/ou revogação do consentimento quanto ao uso de seus dados pessoais; ou
Registro de ocorrência de vazamento de dados;
A exclusão de seus dados pessoais que tenham sido coletados;
Solicitar esclarecimentos quando a utilização de seus dados, dirimir dúvidas e registrar denúncias quando a utilização indevida de seus dados pessoais.
Em caso de solicitação de exclusão dos dados pessoais ou retirada de consentimento para finalidades fundamentais ao regular funcionamento da plataforma e de seus serviços, alguns ambientes e serviços poderão ficar indisponíveis para acesso.
Ainda, alguns dados terão que ser mantidos por período maior ao pedido de exclusão, por força de dispositivos legais (art. 16 da LGPD);
Com o término do prazo de armazenamento, os dados serão excluídos por método de descarte seguro, de modo que não seja possível a identificação dos dados pessoais coletados.
</Text>
      <Text></Text>
      <Text>10. INFORMAÇÕES DESTA POLÍTICA</Text>
      <Text>10.1 Alteração do teor e atualização:</Text>
      <Text>O usuário reconhece o direito da CSG alterar o teor desta Política a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao usuário verificá-la sempre que efetuar o acesso à plataforma ou utilizar nossos serviços.
Ocorrendo atualização neste documento e que demandem nova coleta de consentimento, o usuário será notificado por meio dos canais de contatos que por este forem informados.
Eventuais alterações entrarão em vigor a partir de sua publicação no site e aplicativo da empresa. Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, o usuário consente com as alterações realizadas.
</Text>
      <Text>10.2 Inaplicabilidade:</Text>
      <Text>Caso algum ponto desta Política seja considerado inaplicável por Autoridade de Dados ou judicial, as demais condições permanecerão em pleno vigor e efeito.</Text>
      <Text>10.3 Comunicação Eletrônica:</Text>
      <Text>O usuário reconhece que toda comunicação realizada por e-mail (aos endereços informados no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são válidas, eficazes e suficiente para divulgação de qualquer assunto que se refira aos serviços prestados pela CSG, aos dados fornecidos pelos usuários à empresa, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, sendo exceção apenas o que essa Política prever como tal.
A CSG enviará comunicações de serviço e transacionais como parte integrante do funcionamento de seu aplicativo e site, para usuários cadastrados. É responsabilidade do usuário manter suas informações de contato atualizadas para garantir o recebimento de todas as comunicações importantes da CSG.
</Text>
      <Text>10.4 Canais de Comunicação: </Text>
      <Text>Em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade e Tratamento de Dados, é possível o contato por meio dos Canais de Comunicação apontados a seguir:
Canal de privacidade de dados: privacidade@csg.com.br.  
Prazo para resposta: até 72 (setenta e duas) horas.
Dados do Encarregado: Guilherme Tessari, CPF nº 026.488.000-50. Contato: privacidade@csg.com.br. 
Dados do Controlador: CAMINHOS DA SERRA GAUCHA S.A., pessoa jurídica de direito privado, inscrita no CNPJ sob nº 47.815.827/0001-17. Contato: privacidade@csg.com.br.</Text>
      <Text>10.5 Lei aplicável e foro:</Text>
      <Text>Essa Política será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
Caso o usuário não possua domicílio no Brasil, e em razão dos serviços fornecidos pela CSG apenas em território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser solucionado, a ação deverá ser proposta no Foro da Comarca de Farroupilha/RS.
Última atualização em 6 de novembro de 2023.
</Text>
    </div>
  )
}