import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Image,
  Tooltip,
  useDisclosure,
  Link as ChakraLink,
} from "@chakra-ui/react"

export interface HeaderProps {
  title?: string
  backTo: string
  tip?: string
}

export default function Header(props: HeaderProps) {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

  return (
    <Flex
      w="100%"
      maxWidth="515px"
      justifyContent="space-between"
      alignItems="center"
      m="1.5rem auto 0.5rem auto"
    >
      <Flex alignItems="center">
        <ChakraLink
          display="flex"
          alignItems="center"
          gap="0.5rem"
          w="100%"
          to={props.backTo}
          as={ReactRouterLink}
        >
          <Image src="/images/Back.png" alt="Botão de voltar página" />
          <Text
            color="#2D3748"
            fontSize="0.750rem"
            fontWeight="700"
            fontFamily="Raleway"
          >
            {props.title}
          </Text>
        </ChakraLink>
      </Flex>
      {/* {props.tip && (
        <Flex alignItems="center">
          <Tooltip label={props.tip} isOpen={isOpen}>
            <Image
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              onClick={onToggle}
              src="/images/Question.png"
              alt="Botão de Ajuda"
            />
          </Tooltip>
        </Flex>
      )} */}
    </Flex>
  )
}
