import React, { useCallback, useEffect, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Container,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  Image,
  Tag,
  Divider,
  Box,
  Link as ChakraLink,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from "@chakra-ui/react"
import { PiPlusBold } from "react-icons/pi"
import ActivityHistoryCard from "../../components/activityHistoryCard/ActivityHistoryCard"
import VehicleSlider from "../../components/vehicleSlider/VehicleSlider"
import "./LoggedArea.scss"
import FooterMenu from "../../components/footerMenu/FooterMenu"
import {
  BankAccount,
  Charge,
  SearchFilter,
  SearchParams,
  Vehicle,
} from "../../entities"
import { ChargeService, VehicleService } from "../../services/csg"
import { UserService } from "../../services"
import { BankAccountService } from "../../services/csg/BankAccount"
import Formatter from "../../util/formatter"
import Loader from "../../components/Loader/Loader"
import UserStorage from "../../util/userStorage"
import OneSignalStorage from "../../util/oneSignalStorage"

export default function LoggedArea() {
  const navigate = useNavigate()
  const toast = useToast()

  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [bankAccount, setBankAccount] = useState<BankAccount>(new BankAccount())
  const [charges, setCharges] = useState<Charge[]>([])
  const [selectedCharges, setSelectedCharges] = useState<Charge[]>([])
  const [statusFilter, setStatusFilter] = useState<string | null>(null)

  const vehiclesDisclosue = useDisclosure()

  const handleSelectVehicle = useCallback((plate: string | null) => {
    if (plate) {
      loadChargesByPlates(plate)
    } else {
      loadVeihicles()
      loadBankAccount()
    }
  }, [])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      loadVeihicles()
      loadBankAccount()
      updateOneSignalToken()
    })
    // eslint-disable-next-line
  }, [])

  const loadVeihicles = () => {
    setIsLoading(true)
    const filter = new SearchFilter({ key: "isActive", value: true })
    const params = new SearchParams([filter], 1, 9999)
    VehicleService.search(params)
      .then((result) => {
        const vehicles = result.data.filter(
          (vehicle) => vehicle.isActive === true && vehicle.isRegular === true
        )
        setVehicles(vehicles)

        if (vehicles.length === 0) {
          vehiclesDisclosue.onOpen()
        }

        loadCharges(vehicles.map((vehicle) => vehicle.licensePlate))
      })
      .catch((error) => {
        setIsLoading(false)
        if (error?.response?.status === 403) {
          UserStorage.logout()

          const message: string = error?.response?.data?.message || ""
          if (message.includes("USER")) {
            navigate("/login/bloqueio/usuario")
          } else {
            navigate("/login/bloqueio/ip")
          }
        }
      })
  }

  const loadBankAccount = () => {
    BankAccountService.getByUser()
      .then((bankAccount) => setBankAccount(bankAccount))
      .catch((error) => console.log(error))
  }

  const loadCharges = (licensePlates: string[]) => {
    ChargeService.searchByPlates(licensePlates, null)
      .then((result) => {
        setCharges(result.data)
        setSelectedCharges(result.data)
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const loadChargesByPlates = (licencePlate: string) => {
    setIsLoading(true)
    ChargeService.searchByPlate(licencePlate)
      .then((result) => {
        setCharges(result.data)
        setSelectedCharges(result.data)
      })
      .finally(() => setIsLoading(false))
  }

  const updateOneSignalToken = () => {
    const token = OneSignalStorage.get()
    console.log("atualiza-token", token)
    if (token) {
      UserService.updateOneSignalToken(token)
    }
  }

  const vehiclesFixed = [
    {
      id: 1,
      licensePlate: "RTX 3080",
      manufacturer: "Hyundai",
      model: "HB20",
      status: "#E7ECFF",
      statusBold: "#3531C6",
      transfer: null,
      requested: false,
    },
    {
      id: 2,
      licensePlate: "STF 2A41",
      manufacturer: "Volkswagen",
      model: "Gol",
      status: "#C6F6D5",
      statusBold: "#38A169",
      transfer: null,
      requested: false,
    },
    {
      id: 3,
      licensePlate: "SDH 1329",
      manufacturer: "Hyundai",
      model: "HB20",
      status: "#FEEBCB",
      statusBold: "#F26522",
      transfer: null,
      requested: false,
    },
    {
      id: 4,
      licensePlate: "GTX 1650",
      manufacturer: "Volkswagen",
      model: "Gol",
      status: "#FFF5F5",
      statusBold: "#E53E3E",
      transfer: "pending",
      requested: false,
    },
    {
      id: 5,
      licensePlate: "ATG 2050",
      manufacturer: "Hyundai",
      model: "HB20",
      status: "#FFF5F5",
      statusBold: "#E53E3E",
      transfer: "pending",
      requested: true,
    },
  ]

  return (
    <Flex
      w="100%"
      maxWidth="515px"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      m="0 auto"
    >
      {isLoading && <Loader />}
      <Flex w="100%" direction="column">
        <Flex w="100%" bg="#00335B" minH="28.5rem" p="1rem">
          <Flex w="100%" fontFamily="Raleway" direction="column">
            <Text
              m="1.5rem 0 0.5rem 0"
              color="#fff"
              fontSize="1rem"
              fontWeight="700"
            >
              Seu saldo
            </Text>
            <HStack gap="0.625rem">
              <Flex bg="#E7ECFF14" p="0.75rem" borderRadius="4.375rem">
                <Image src="/images/card-icon/Coins.svg" alt="Ícone do saldo" />
              </Flex>
              <Text color="#fff" fontSize="3.75rem" fontWeight="700">
                {Formatter.formatCurrency(bankAccount.balance)}
              </Text>
            </HStack>
            <HStack
              bg="#E7ECFF0A"
              my="1.25rem"
              p="0.5rem 0.9375rem"
              borderRadius="0.375rem"
              gap="0.625rem"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                src="/images/StarBlueIcon.png"
                alt="Ícone da varinha mágica"
              />
              <Text color="#CFD9EA" fontSize="0.75rem" fontWeight="500">
                Cobranças são pagas automaticamente com saldo disponível; sem
                saldo, o pagamento é manual.
              </Text>
            </HStack>
            <ChakraLink w="100%" as={ReactRouterLink} to="/adicionar-saldo">
              <Button
                size="lg"
                w="100%"
                bg="#00D2A0"
                color="#fff"
                fontSize="1rem"
                fontWeight="700"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="2"
              >
                <PiPlusBold /> Adicionar saldo
              </Button>
            </ChakraLink>
          </Flex>
        </Flex>
        <Box w="100%" className="float-container">
          <Text
            pl="1rem"
            mb="0.5rem"
            color="#fff"
            fontSize="1rem"
            fontWeight="700"
            alignSelf="flex-start"
          >
            Meus veículos
          </Text>
          <Box>
            <VehicleSlider
              vehicles={vehicles}
              onSelectedVehicle={handleSelectVehicle}
              zIndex={99}
            />
          </Box>
        </Box>
        <VStack
          w="100%"
          p="1rem"
          flex-shrink="0"
          bg="#fff"
          flexDirection="column"
          gap="3"
        >
          <Text
            color="#2D3748"
            fontSize="1rem"
            fontWeight="700"
            alignSelf="flex-start"
          >
            Histórico
          </Text>
          <HStack w="full" display="flex" justifyContent="space-between">
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              gap="0.5rem"
              border="1px solid #8E99AB"
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              bg={statusFilter === null ? "#F26522" : "#fff"}
              fontSize="0.875rem"
              fontWeight="500"
              color={statusFilter === null ? "#fff" : "#4A5568"}
              cursor="pointer"
              onClick={() => setStatusFilter(null)}
            >
              Tudo
            </Tag>
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              gap="0.5rem"
              border="1px solid #8E99AB"
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              colorScheme="gray"
              bg={statusFilter === "open" ? "#F26522" : "#fff"}
              fontSize="0.875rem"
              fontWeight="500"
              color={statusFilter === "open" ? "#fff" : "#4A5568"}
              cursor="pointer"
              onClick={() => setStatusFilter("open")}
            >
              Pendentes
            </Tag>
            <Tag
              display="flex"
              padding="0 0.75rem"
              alignItems="center"
              gap="0.5rem"
              border="1px solid #8E99AB"
              borderRadius="0.375rem"
              size="md"
              variant="solid"
              colorScheme="gray"
              bg={statusFilter === "paid" ? "#F26522" : "#fff"}
              fontSize="0.875rem"
              fontWeight="500"
              color={statusFilter === "paid" ? "#fff" : "#4A5568"}
              cursor="pointer"
              onClick={() => setStatusFilter("paid")}
            >
              Pagos
            </Tag>
          </HStack>

          {charges.filter((charge) => charge.status === "open").length > 0 && (
            <Flex
              w="100%"
              p="1.13rem 1.25rem"
              bg="#E7ECFFB2"
              borderRadius="0.375rem"
              direction="column"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                mb="0.750rem"
              >
                <Flex alignItems="center" gap="0.5rem">
                  <Image
                    src="/images/RedWarning.png"
                    alt="Ícone de aviso de atenção"
                  />
                  <Text
                    color="#2D3748"
                    fontWeight="700"
                    fontSize="0.875rem"
                    maxW="165px"
                  >
                    Você possui{" "}
                    {
                      charges.filter((charge) => charge.status === "open")
                        .length
                    }{" "}
                    cobranças em aberto
                  </Text>
                </Flex>
                <Text color="#2D3748" fontWeight="700" fontSize="1.25rem">
                  {Formatter.formatCurrency(
                    charges
                      .filter((charge) => charge.status === "open")
                      .reduce(
                        (sum, charge: Charge) =>
                          sum +
                          (charge.rateAmount + charge.fee - charge.discount),
                        0
                      )
                  )}
                </Text>
              </Flex>
              <Flex w="100%">
                <ChakraLink w="100%" as={ReactRouterLink} to="/checkout">
                  <Button
                    w="100%"
                    size="md"
                    fontSize="0.875rem"
                    variant="solid"
                    bg="#00335B"
                    color="#fff"
                    _hover={{ bg: "#003f70", transition: "0.3s" }}
                  >
                    Pagar agora
                  </Button>
                </ChakraLink>
              </Flex>
            </Flex>
          )}

          <HStack
            bg="#F7F8F9"
            p="0.625rem 2rem 0.625rem 0.75rem"
            borderRadius="0.5rem"
            display="flex"
            gap="0.625rem"
            alignItems="flex-start"
          >
            <Image src="/images/card-icon/Info.svg" alt="Ícone de informação" />
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              Se o seu veículo passou por um Pórtico Free Flow da CSG, a
              transação pode estar sendo processada e será disponibilizada para
              pagamento em breve.
            </Text>
          </HStack>
          <Divider orientation="horizontal" />
          <Flex w="full" direction="column" gap="2">
            {selectedCharges
              .filter((charge) =>
                statusFilter === null ? charge : charge.status === statusFilter
              )
              .map((charge) => (
                <>
                  <ActivityHistoryCard
                    id={charge.id}
                    icon="/images/card-icon/HourglassHigh.svg"
                    title={charge.licensePlate}
                    status={charge.status}
                    statusCode={charge.status}
                    value={Formatter.formatChargeTotal(charge)}
                    date={Formatter.formatDateTime(charge.datetimeOccurrence)}
                    dueDate={Formatter.formatDate(charge.dueDate)}
                    subTitle={charge.plazaDescription}
                    arrow={false}
                  />
                  <Divider orientation="horizontal" />
                </>
              ))}
          </Flex>
        </VStack>
      </Flex>
      <Modal
        isOpen={vehiclesDisclosue.isOpen}
        onClose={() => navigate("/veiculo/form")}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastre seus Veículos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Você não tem veículo cadastrado.
            <Flex alignContent="center" marginTop="20px">
              <Button margin="auto" onClick={() => navigate("/veiculo/form")}>
                Cadastre Agora
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <FooterMenu />
    </Flex>
  )
}
