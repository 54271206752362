import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Checkbox,
  Textarea,
  Image,
  CloseButton,
} from "@chakra-ui/react"
import VehicleCard from "../../../components/vehicleCard/VehicleCard"
import { RiAttachment2 } from "react-icons/ri"
import Layout from "../../../components/layout/Layout/Layout"
import { VehicleService } from "../../../services/csg"
import { Vehicle, VehicleTransfer } from "../../../entities"
import { VehicleTransferService } from "../../../services/csg/VehicleTransferService"
import { UserService } from "../../../services"
import UserStorage from "../../../util/userStorage"
import Loader from "../../../components/Loader/Loader"

export default function VehicleRequest() {
  const navigate = useNavigate()

  const params = useParams()
  const [vehicle, setVehicle] = useState<Vehicle | null>(null)
  const [form, setForm] = useState<VehicleTransfer>(new VehicleTransfer())
  const [loading, setLoading] = useState(false)

  const [selectedReasons, setSelectedReasons] = useState<any[]>([])

  const reasons = [
    { key: "buy", label: "Comprei o veículo" },
    {
      key: "financialResponsible",
      label: "Sou o novo responsável pelos pagamentos",
    },
    { key: "mistake", label: "Cadastraram meu veículo por engano" },
    { key: "other", label: "Outro motivo" },
  ]

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      console.log("is-valid-user", isValid)
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (params?.id) load(parseInt(params.id))
  }, [params?.id])

  const load = (id: number) => {
    VehicleService.get(id).then((vehicle) => setVehicle(vehicle))
  }

  const handleReason = (key: string, checked: boolean) => {
    const newSelectedReasons = [...selectedReasons]
    if (checked) {
      const reason = reasons.find((item) => item.key === key)
      newSelectedReasons.push(reason)
    } else {
      const idx = newSelectedReasons.findIndex((item) => item.key === key)
      newSelectedReasons.splice(idx, 1)
    }
    setSelectedReasons(newSelectedReasons)
  }

  const save = () => {
    const transfer = new VehicleTransfer(form)
    if (vehicle) {
      transfer.vehicle = vehicle
      console.log("transfer", transfer)
    }
    transfer.reason = selectedReasons.map((item) => item.label).join("; ")

    if (!transfer.reason) {
      alert("Selecione pelo menos um motivo.")
      return
    }

    setLoading(true)
    VehicleTransferService.create(transfer)
      .then((transfer) => {
        navigate("/veiculo")
      })
      .finally(() => setLoading(false))
  }

  const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files === null) {
      alert("Selecione um arquivo.")
      return
    }

    const validTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
    ]

    const file = files[0]
    console.log(file)

    if (!validTypes.includes(file.type)) {
      alert("Selecione uma imagem ou PDF.")
      return
    }

    const img = {
      preview: URL.createObjectURL(file),
      data: file,
    }

    let formData = new FormData()
    formData.append("file", img.data)

    setLoading(true)
    VehicleTransferService.uploadDocument(formData)
      .then((document) => {
        const documents = [...form.documents]
        documents.push(document)
        setForm({ ...form, documents })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Layout
      internal={true}
      header={{
        title: "",
        backTo: "/veiculo",
      }}
      valign="space-between"
      menuSelected="vehicle"
    >
      {loading && <Loader />}
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
      >
        <Flex>
          <Text color="#2D3748" fontSize="1.5rem" fontWeight="700">
            Abrir solicitação
          </Text>
        </Flex>

        <VStack>
          <FormLabel
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Veículo
          </FormLabel>
          {vehicle && (
            <VehicleCard
              id={vehicle.id}
              licensePlate={vehicle.licensePlate}
              manufacturer={vehicle.manufacturer}
              model={vehicle.model}
              status=""
              statusBold=""
              // transfer={carItem.transfer}
              isRegular={true}
              requested={false}
            />
          )}
        </VStack>

        <FormControl
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          gap="1rem"
        >
          <FormLabel
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Motivo da transferência
          </FormLabel>
          {reasons.map((reason) => (
            <Checkbox
              key={reason.key}
              size="md"
              fontWeight="400"
              color="#1A202C"
              onChange={(event) =>
                handleReason(reason.key, event.target.checked)
              }
            >
              {reason.label}
            </Checkbox>
          ))}
        </FormControl>
        <FormControl>
          <FormLabel
            fontSize="1rem"
            fontWeight="700"
            color="#2D3748"
            alignSelf="flex-start"
          >
            Explique a razão da transferência
          </FormLabel>
          <Textarea
            placeholder="Fale mais sobre a transferência"
            value={form.explanation}
            onChange={(event) =>
              setForm({ ...form, explanation: event.target.value })
            }
          />
        </FormControl>
        <FormControl alignItems="flex-start">
          <FormLabel
            htmlFor="inputFile"
            fontSize="0.875rem"
            fontWeight="600"
            color="#3531C6"
            display="flex"
            alignItems="center"
            gap="2"
          >
            <RiAttachment2 />
            Anexar documentos
          </FormLabel>
          <Text fontSize="0.75rem" fontWeight="400" color="#A2ACBD">
            Formatos aceitos: jpg, pdf, png.
          </Text>
          <Text fontSize="0.75rem" fontWeight="400" color="#A2ACBD">
            Tamanho máximo de documentos: 3MB
          </Text>
          <Input type="file" id="inputFile" display="none" onChange={upload} />
          <Flex mt="1rem" w="100%" direction="column" gap="0.5rem">
            {form.documents.map((document) => (
              <Flex
                w="100%"
                bg="#F7F8F9"
                p="0.375rem 0.750rem"
                borderRadius="0.375rem"
                boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
                alignItems="center"
                justifyContent="space-between"
                key={`document-${document.path}`}
              >
                <Flex w="100%" alignItems="center" gap="0.5rem">
                  <Image
                    src="/images/InputFileIcon.png"
                    alt="Ícone de inserção de arquivo"
                  />
                  <Text noOfLines={[1, 2, 3]}>{document.originalname}</Text>
                </Flex>
                <Flex>
                  <CloseButton
                    size="md"
                    onClick={() => {
                      const documents = [...form.documents]
                      const idx = documents.findIndex(
                        (doc) => doc.name === document.name
                      )
                      if (idx >= 0) {
                        documents.splice(idx, 1)
                        setForm({ ...form, documents })
                      }
                    }}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </FormControl>
      </Flex>
      <Flex
        w="100%"
        minH="100px"
        maxWidth="515px"
        gap="1.5rem"
        alignItems="flex-end"
      >
        <Button
          w="100%"
          size="lg"
          fontSize="1rem"
          mt="3"
          bg="#00335B"
          color="white"
          _hover={{ bg: "#003f70", transition: "0.3s" }}
          onClick={save}
        >
          Enviar
        </Button>
      </Flex>
    </Layout>
  )
}
