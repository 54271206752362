import { User } from "../../entities"
import { AuthRepository } from "../../repositories/auth/AuthRepository"
import UserStorage from "../../util/userStorage"

export class AuthService {
  public static async login(
    email: string,
    password: string,
    oneSignalToken: string
  ): Promise<void> {
    const token = await AuthRepository.login(email, password, oneSignalToken)
    UserStorage.setToken(token)
  }

  public static async sendCode(id: number, type: string): Promise<void> {
    const token = UserStorage.getToken()
    await AuthRepository.sendCode(id, type, token)
  }

  public static async activateAccount(code: number): Promise<boolean> {
    const token = UserStorage.getToken()
    const newToken = await AuthRepository.activateAccount(code, token)
    if (newToken) {
      UserStorage.setToken(newToken)
      return true
    }
    return false
  }

  public static async forgotPasswordWithEmail(
    email: string,
    type: string
  ): Promise<string> {
    return await AuthRepository.forgotPasswordWithEmail(email, type)
  }

  public static async forgotPasswordWithDocument(
    document: string,
    type: string
  ): Promise<string> {
    return await AuthRepository.forgotPasswordWithDocument(document, type)
  }

  public static async isValidRecoveryPasswordCode(
    email: string,
    document: string,
    code: number
  ): Promise<boolean> {
    return await AuthRepository.isValidRecoveryPasswordCode(
      email,
      document,
      code
    )
  }

  public static async recoveryPassword(
    email: string,
    document: string,
    code: number,
    newPassword: string,
    newPasswordConfirm: string
  ): Promise<boolean> {
    return await AuthRepository.recoveryPassword(
      email,
      document,
      code,
      newPassword,
      newPasswordConfirm
    )
  }

  public static async getUserByToken(): Promise<User> {
    const token = UserStorage.getToken()
    return await AuthRepository.getUserByToken(token)
  }

  public static async changePassword(
    password: string,
    newPassword: string,
    newPasswordConfirm: string
  ): Promise<boolean> {
    const token = UserStorage.getToken()
    return await AuthRepository.changePassword(
      password,
      newPassword,
      newPasswordConfirm,
      token
    )
  }
}
