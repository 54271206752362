export class SearchResultInfo {
    public page = 0
    public pages = 0
    public pageSize = 0
    public count = 0

    constructor (data: any = {}) {
        this.page = data?.page ? parseInt(data.page) : 0
        this.pages = data?.pages ? parseInt(data.pages) : 0
        this.pageSize = data?.pageSize ? parseInt(data.pageSize) : 0
        this.count = data?.count ? parseInt(data.count) : 0
    }
}