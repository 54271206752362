const storageKey = "@mobile"
export default class MobileStorage {
  static isMobile = (): boolean => {
    const isMobile = localStorage.getItem(storageKey)
    return !!isMobile
  }

  static setMobile = (): void => {
    localStorage.setItem(storageKey, '1')
  }
}