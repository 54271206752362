import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { Flex, Text, Button, Link as ChakraLink } from "@chakra-ui/react"
import { FiSend } from "react-icons/fi"
import Layout from "../../../components/layout/Layout/Layout"

export default function VehicleRequestSent() {
  return (
    <Layout
      internal={false}
      header={{
        title: "",
        backTo: "/",
        tip: "Dúvidas acesse nosso site",
      }}
      valign="space-between"
    >
      <Flex
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        justifyContent="center"
        m="auto 0"
      >
        <Flex
          color="white"
          alignItems="center"
          fontSize="4rem"
          justifyContent="center"
          bg="#F26522"
          borderRadius="50%"
          height="7rem"
          w="7rem"
          margin="0 auto"
        >
          <FiSend />
        </Flex>
        <Flex direction="column">
          <Text
            color="#2D3748"
            fontSize="1.5rem"
            fontWeight="700"
            textAlign="center"
          >
            Solicitação enviada
          </Text>
          <Text
            color="#4A5568"
            fontSize="0.875rem"
            fontWeight="400"
            textAlign="center"
          >
            Dentro de um curto período, nossa equipe entrará em contato através
            do endereço de e-mail{" "}
            <Text
              color="#2B28A3"
              fontSize="0.875rem"
              fontWeight="400"
              textAlign="center"
            >
              email@provedor.com.br
            </Text>
            .
          </Text>
          <Text
            color="#8E99AB"
            fontSize="0.875rem"
            fontWeight="400"
            textAlign="center"
          >
            Fique atento à sua caixa de entrada para receber nossas
            comunicações. Se surgirem mais questionamentos, estaremos à
            disposição para ajudar.
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        maxWidth="515px"
        minH="100px"
        justifyContent="center"
        alignItems="flex-end"
      >
        <ChakraLink w="full" as={ReactRouterLink} to="/veiculo">
          <Button
            w="full"
            size="lg"
            fontSize="1rem"
            bg="#00335B"
            color="white"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
          >
            Voltar
          </Button>
        </ChakraLink>
      </Flex>
    </Layout>
  )
}
