export class OrderTotals {
  public id: number
  public rateAmount: number
  public discount: number
  public fee: number
  public total: number
  
  public constructor(data: any = {}) {
    this.id = data.id || 0
    this.rateAmount = data.rateAmount || 0
    this.discount = data.discount || 0
    this.fee = data.fee || 0
    this.total = data.total || 0
  }
}