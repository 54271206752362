import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class PostcodeRepository {
  public static async searchPostcode(postcode: string): Promise<any> {
    const url = `https://viacep.com.br/ws/${postcode}/json/`
    const response = await client.DoRequestDirect("GET", url)
    return response
  }
}
