export class OrderSimulation {
  public rateAmount = 0
  public discount = 0
  public fee = 0
  public total = 0

  public constructor(data: any = {}) {
    this.rateAmount = data.rateAmount ? parseFloat(data.rateAmount) : 0
    this.discount = data.discount ? parseFloat(data.discount) : 0
    this.fee = data.fee ? parseFloat(data.fee) : 0
    this.total = data.total ? parseFloat(data.total) : 0
  }
}
