import { BalanceReturn } from "../../entities/csg/BalanceReturn"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class BalanceReturnRepository {
  public static async create(balanceReturn: BalanceReturn, token: string): Promise<BalanceReturn> {
    const headers = { Authorization: `Bearer ${token}`}
    const response = await client.DoRequest("POST", "/balance-return", balanceReturn, headers)

    return new BalanceReturn(response)
  }
}