import { Role, SearchParams, SearchResult } from "../../entities"
import { RoleRepository } from "../../repositories/user/RoleRepository"
import UserStorage from "../../util/userStorage"

export class RoleService {
  public static async search(params: SearchParams): Promise<SearchResult<Role>> {
    const token = UserStorage.getToken()
    const result = await RoleRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<Role> {
    const token = UserStorage.getToken()
    return await RoleRepository.get(id, token)
  }

  public static async getByCode(code: string): Promise<Role> {
    return await RoleRepository.getByCode(code)
  }

  public static async create(role: Role): Promise<Role> {
    const token = UserStorage.getToken()
    return await RoleRepository.create(role, token)
  }

  public static async update(role: Role): Promise<Role> {
    const token = UserStorage.getToken()
    return await RoleRepository.update(role, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await RoleRepository.delete(id, token)
  }

}