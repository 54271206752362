import React from "react"
import { Flex, Text, HStack, Image } from "@chakra-ui/react"

interface LicensePlateProps {
  licensePlate: string
}

export default function LicensePlateCard(props: LicensePlateProps) {
  return (
    <HStack w="full" fontFamily="Raleway" gap={3} justifyContent="center">
      <Flex p="0.875rem" bg="#E2E8F0" color="#2D3748" borderRadius="0.750rem">
        <Image src="/images/card-icon/Car.svg" alt="Ícone de um carro" />
      </Flex>
      <Text color="#1A202C" fontSize="1.5rem" fontWeight="700">
        {props.licensePlate}
      </Text>
    </HStack>
  )
}
